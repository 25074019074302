import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaUser, FaTransgender, FaGem, FaLocationArrow, FaHandHoldingMedical, FaBriefcaseMedical } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { NavLink, useNavigate } from "react-router-dom";
import loinback from "../Compontes/Img/loginBack.png";
import './login.css';
import { useToken } from "../store";

const SignUpForm = () => {
  const [name, setName] = useState("");
  const [username, setuserName] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [address, setAddress] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [profession, setProfession] = useState("");
  const [number, setNumber] = useState("");
  const [insurance, setInsurance] = useState("");
  const [insuranceType, setInsuranceType] = useState("");
  const [insuranceCompany, setInsuranceCompany] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const {token, setToken} = useToken(state=>state)
  const handleSubmit = (event) => {
    event.preventDefault();
  
    // Basic validation for required fields
    if (
      !name ||
      !gender ||
      !bloodGroup 
    ) {
      toast.error("Please fill in all required fields", {
        autoClose: 3000,
      });
      return;
    }
  
    // Validate phone number length
    if (number.length !== 10) {
      toast.error("Contact number must be 10 digits long", {
        autoClose: 3000,
      });
      return;
    }
  
    // Regex for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address", {
        autoClose: 3000,
      });
      return;
    }
  
    // Regex to ensure the name doesn't start with a space
    const nameRegex = /^[^\s][\w\s]*$/;
    if (!nameRegex.test(name)) {
      toast.error("Name cannot start with a space", {
        autoClose: 3000,
      });
      return;
    }
  
    // Regex to prevent spaces in username
    const usernameRegex = /^\S*$/;
    if (!usernameRegex.test(username)) {
      toast.error("Username cannot contain spaces", {
        autoClose: 3000,
      });
      return;
    }
  
    // Proceed with signup if all validations pass
    signUpUser();
  };
  

    useEffect(() => {
      // Retrieve the mobile number from localStorage and set it to state
      const storedMobile = localStorage.getItem("mobile");
      if (storedMobile) {
        setNumber(storedMobile);
      }
    }, []);

  async function signUpUser() {
    setLoading(true); // Start loading state
    try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        // myHeaders.append("Authorization", `${token}`);

        const raw = JSON.stringify({
          email: email,
          fullName: name,
          mobile: number,
          gender: gender,
          age: age,
          address: address,
          bloodGroup: bloodGroup,
          profession: profession,
          hasInsurance: insurance,
          insuranceType: insuranceType,
          insuranceCompany: insuranceCompany,
        });

        const requestOptions = {
            method: "POST",
            // headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        const response = await fetch(`https://newapi.jaishreeramhealthcareprivateltd.com/users/signup`, requestOptions);
        const result = await response.json();

        if (result.success === true) {
          // console.log(result.token,"jkjkk")
          localStorage.setItem("token", result.token);
          setToken(result.token)
            navigate("/home");

            // Clear all form fields on success
            setName("");
            setBloodGroup("");
            setAddress("");
            setNumber("");
            setAge("");
            setEmail("");
            setProfession("");
            setInsurance("");
            setInsuranceCompany("");
            setInsuranceType("");
            setGender("");
            toast.success(result.message, { autoClose: 3000 });
          } else {
            toast.error(result.message || "Error updating profile");
          }
            // Navigate to Home screen
    } catch (error) {
        console.log("Error during signup:", error); // Log any errors
    } finally {
        setLoading(false); // Stop loading state
    }
}

  

  return (
    <div style={{ overflowX: "hidden" }}>
      <ToastContainer />
      <div
        className="container-fulid imgsee"
        style={{
          backgroundImage: `url(${loinback})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          alignItems: "center",
        }}
      >
        <div style={{ backgroundColor: "rgba(0, 0, 0, 0.6)", justifyContent: "center", alignItems: "center", display: "flex" }}>
          <form className="form" onSubmit={handleSubmit}>
            <div className="input-container">
              <FaUser size={24} />
              <input
                className="input"
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            
            <div className="input-container">
              <FaUser size={24} />
              <input
                className="input"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="input-container">
              <IoCall size={24} />
              <input
                className="input"
                type="number"
                placeholder="Number"
                value={number}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    setNumber(e.target.value);
                  }
                }}
              />
            </div>
            <div className="input-container">
            <FaTransgender size={24} />
            <select
              className="input"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <option value="" disabled>
                Select Gender
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
          
            <div className="input-container">
              <FaGem size={24} />
              <input
                className="input"
                type="number"
                placeholder="Age"
                value={age}
                onChange={(e) => setAge(e.target.value)}
              />
            </div>
            <div className="input-container">
              <FaLocationArrow size={24} />
              <input
                className="input"
                type="text"
                placeholder="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="input-container">
  <FaHandHoldingMedical size={24} />
  <select
    className="input"
    value={insurance}
    onChange={(e) => setInsurance(e.target.value)}
  >
    <option value="" disabled>
      Do you have Insurance?
    </option>
    <option value="YES">YES</option>
    <option value="NO">NO</option>
  </select>
</div>

            <div className="input-container">
              <FaHandHoldingMedical size={24} />
              <input
                className="input"
                type="text"
                placeholder="Insurance Type"
                value={insuranceType}
                onChange={(e) => setInsuranceType(e.target.value)}
              />
            </div>
            <div className="input-container">
              <FaHandHoldingMedical size={24} />
              <input
                className="input"
                type="text"
                placeholder="Insurance Company"
                value={insuranceCompany}
                onChange={(e) => setInsuranceCompany(e.target.value)}
              />
            </div>
            <label className="label">Blood Group</label>
            <div className="blood-group-container">
              {['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'].map((group) => (
                <button
                  key={group}
                  type="button"
                  className={`blood-group-button ${bloodGroup === group ? 'selected' : ''}`}
                  onClick={() => setBloodGroup(group)}
                >
                  {group}
                </button>
              ))}
            </div>
            <div className="input-container">
              <FaBriefcaseMedical size={24} />
              <input
                className="input"
                type="text"
                placeholder="Profession"
                value={profession}
                onChange={(e) => setProfession(e.target.value)}
              />
            </div>
            <button type="submit" className="button" disabled={loading} style={{ backgroundColor: "#0539FF", display: "flex", justifyContent: "center", alignItems: "center" }}>
              {loading ? "Signing Up..." : "Sign Up"}
            </button>



            <div style={{display:'flex', justifyContent:'space-around '}}>
             <NavLink to="/terms"  >  <span className="text-white"  >Terms & condition </span> </NavLink>
              <NavLink to="/privacy"  > <span className="text-white" >Privacy Policy</span> </NavLink>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;
