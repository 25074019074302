import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "../Footer";
import baner from '../Img/baner.png';
import { useNavigate } from "react-router-dom";
import { BallTriangle } from "react-loader-spinner";
import locations from '../Img/locations.png'
import { param } from "jquery";
import { toast, ToastContainer } from "react-toastify";
import { useToken } from "../../store";
function CategeroyCards() {
    const [loading, setloading] = useState(false)
    const [data, setData] = useState([])
    const navigate = useNavigate()
    const [city, setCity] = useState([])
    const [cityid, setnameCityId] = useState("")
    const [selectedCity, setSelectedCity] = useState({ id: "", name: "" })
    const {token, setToken} = useToken(state=>state)
    useEffect(() => {
        category()
        getCity()
    }, [])
    const category = async () => {
        setloading(true)
        try {
            const myHeaders = new Headers();
   
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            fetch("https://newapi.jaishreeramhealthcareprivateltd.com/category/categories/Others", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    {
                        if(result.success === true){
                            console.log(result.data, "kajal")
                            setData(result.data)

                        }else{
                            console.log(result.message,"error catergery")
                        }
                    }
                }).finally(()=>{
                    setloading(false)
                })
        } catch (error) {
            console.log(error)
        }
    }


    const handleNavigation = (selectedCategoryId) => {
        if (selectedCity && selectedCity.name) {
            navigate("/Hospitlas", {
                state: { selectedCategoryId, selectedCity: selectedCity.name },
            });
        } else {
            toast.error("Please select a city before proceeding!", { autoClose: 2000 });
        }
    };

    const getCity = async () => {
        setloading(true);
        try {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };

            const response = await fetch(
                "https://newapi.jaishreeramhealthcareprivateltd.com/cityRoute/cities",
                requestOptions
            );
            const result = await response.json();
            setCity(result.cities);
            setnameCityId(result.cities._id)
            // console.log(result.cities._id, "kkkk")
        } catch (error) {
            console.log(error);
        } finally {
            setloading(false);
        }
    };
    const handleCityChange = (e) => {
        const selectedIndex = e.target.selectedIndex;
        const selectedCityName = e.target[selectedIndex].text;
        const selectedCityId = e.target.value;
    
        // Store the selected city in localStorage
        const selectedCity = { id: selectedCityId, name: selectedCityName };
        localStorage.setItem("selectedCity", JSON.stringify(selectedCity));
    
        // Update state with the selected city
        setSelectedCity(selectedCity);
    };
    useEffect(() => {
        const savedCity = localStorage.getItem("selectedCity");
        if (savedCity) {
            setSelectedCity(JSON.parse(savedCity));
        }
    }, []);
    return <div>
    <ToastContainer/>
        <section style={{marginBottom:"50px"}}>
            <Navbar />
        </section>
        <div style={{ backgroundColor: "pink", display: "flex", alignItems: "center", gap: "40px", padding: "10px",height:"100px", marginTop:"100px"}}>
        <img src={locations} style={{ height: 20, width: 20, marginLeft: "10px" , }} />
        <select
        className="form-select"
        aria-label="Default select example"
        style={{ width: "100%", border: "none", listStyle: "none" }}
        onChange={handleCityChange}
        value={selectedCity?.id || ""}
    >
        <option value="" disabled>
            Please select a city
        </option>
        {city.map((cityItem, index) => (
            <option key={index} value={cityItem._id}>
                {cityItem.name}
            </option>
        ))}
    </select>
      
      </div>
        <section>
            <div className="container-fluid pt-4">
                <div id="carouselExampleSlidesOnly" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div style={{ width: "100%", backgroundSize: "cover", objectFit: "cover" }}>
                                <img src={baner} style={{ height: "100%", width: "100%" }} />
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div style={{ width: "100%", backgroundSize: "cover", objectFit: "cover" }}>
                                <img src={baner} style={{ height: "100%", width: "100%" }} />
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div style={{ width: "100%", backgroundSize: "cover", objectFit: "cover" }}>
                                <img src={baner} style={{ height: "100%", width: "100%" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className="container">
                <div className="row pt-5 expolore">
                    <div className="  top pe-5 d-flex justify-content-between ">
                        <h3 style={{ fontFamily: "Inter", color: "#FF1493", fontWeight: "600" }}>Explore Categories</h3>

                    </div>
                    {
                        loading
                            ?
                            <BallTriangle
                                height={50}
                                width={50}
                                radius={5}
                                color="#4fa94d"
                                ariaLabel="ball-triangle-loading"
                                visible={true}
                            /> :
                            data.map((res) => (
                                <button
                                    style={{ border: "none", background: "none" }}
                                    onClick={()=>{handleNavigation(res._id)}}
                                    className="col-lg-2 col-md-6 col-sm-6 col-6 mb-4"
                                >
                                    <div style={{
                                        backgroundColor: "#FF1493",
                                        borderRadius: "10px",
                                        overflow: "hidden"
                                    }}>

                                        <div style={{ height: "130px", width: "100%" }}>
                                            <img
                                                src={res.category_image}
                                                style={{
                                                    height: "100%",
                                                    width: "100%",
                                                    objectFit: "cover"
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                backgroundColor: "#FF1493",

                                            }}
                                        >
                                            <p className="fs-5 text-white p-2" style={{ textAlign: "center" }}>
                                                {res.category_name}
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            ))}

                </div>
            </div>
        </section>

        <section>
            <Footer />
        </section>
    </div>;
}

export default CategeroyCards;
