import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import baner from '../Img/baner.png';
import pro from '../Img/pro.png';
import malthora from '../Img/malthora.png';
import top from '../Img/banner.jpg';
import './home.css';
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaWhatsapp } from 'react-icons/fa';
import video from '../Img/video.mp4'
import video1 from '../Img/jsrv2.mp4'
import { BallTriangle } from "react-loader-spinner";
import TinySlider from 'tiny-slider-react';
import 'tiny-slider/dist/tiny-slider.css';
import locations from '../Img/locations.png'
import { param } from "jquery";
import { toast, ToastContainer } from "react-toastify";
import { useToken } from "../../store";
import Select from "react-select";
import { FaMapMarkerAlt } from "react-icons/fa";
import VideoList from "../../VideoList";

function Home() {
    const { token, setToken } = useToken(state => state)

    const [loading, setloading] = useState(false)
    const [data, setData] = useState([])
    const [city, setCity] = useState([])
    const [selectedCity, setSelectedCity] = useState({ id: "", name: "" })

    const [Banners, setBanners] = useState([])
 

    const firstslide = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };



    const navigate = useNavigate()
    useEffect(() => {
        category()
        getCity()
        HomeBnaner() 
    }, [])

    const category = async () => {
        setloading(true);
        try {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);
            console.log(token, "Token value");
    
            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };
    
            const response = await fetch("https://newapi.jaishreeramhealthcareprivateltd.com/category/categories/Home", requestOptions);
            const result = await response.json();
    
            console.log(result, "API Response");
    
            if (result.success) {
                console.log(result.data.homeCategories, "Valid categories");
                setData(result.data.homeCategories || []); // Ensure fallback to empty array
            } else {
                console.log(result.message, "Error fetching categories");
                setData([]); // Reset data on error
            }
        } catch (error) {
            console.error(error, "API Error");
        } finally {
            setloading(false);
        }
    };



    const handleNavigation = (selectedCategoryId) => {
        if (selectedCity && selectedCity.name) {
            navigate("/Hospitlas", {
                state: { selectedCategoryId, selectedCity: selectedCity.name },
            });
        } else {
            toast.error("Please select a city before proceeding!", { autoClose: 2000 });
        }
    };
    




    const HomeBnaner = async () => {
        setloading(true)
        try {
            const myHeaders = new Headers();
            // const token = localStorage.getItem('token')
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            fetch("https://newapi.jaishreeramhealthcareprivateltd.com/banner/getHomeBanners", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    setBanners(result.data)
                    // console.log(result, ";;;;")
                }).finally(() => {
                    setloading(false)
                })
        } catch (error) {
            console.log(error)
        }
    }



     

    const settingss1 = {
        items: 1,
        slideBy: 1,
        autoplay: true,
        autoplayTimeout: 2000,
        controls: false,
        nav: false,
        autoplayButtonOutput: false,
        responsive: {
            768: {
                items: 1,
            },
        },
    };
    const settingss = {
        items: 2,
        slideBy: 1,
        autoplay: true,
        autoplayTimeout: 2000,
        controls: false,
        nav: false,
        autoplayButtonOutput: false,
        responsive: {
            768: {
                items: 2,
            },
        },
    };
    const settingssBest = {
        items: 1,
        slideBy: 1,
        autoplay: true,
        autoplayTimeout: 2000,
        controls: false,
        nav: false,
        autoplayButtonOutput: false,
        responsive: {
            0: { 
                items: 1,   
            },
            768: {  
                items: 1,   
            },
            1024: {   
                items: 1,
            },
        },
    };
    const settingssBest12 = {
        items: 2,
        slideBy: 1,
        autoplay: true,
        autoplayTimeout: 2000,
        controls: false,
        nav: false,
        autoplayButtonOutput: false,
        responsive: {
            0: { 
                items: 2,   
            },
            768: {  
                items: 3,   
            },
            1024: {   
                items: 4,
            },
        },
    };

    const settingsss = {
        items: 4,   
        slideBy: 1,
        autoplay: true,
        autoplayTimeout: 2000,
        controls: false,
        nav: false,
        autoplayButtonOutput: false,
        responsive: {
            768: {  
                items: 1,   
            },
            1024: {   
                items: 4,
            },
        },
    };
    const settingsss1 = {
        items: 4,   
        slideBy: 1,
        autoplay: true,
        autoplayTimeout: 2000,
        controls: false,
        nav: false,
        autoplayButtonOutput: false,
        responsive: {
            0: { 
                items: 2,   
            },
            768: {  
                items: 1,   
            },
            1024: {   
                items: 4,
            },
        },
    };
    const settingsss2 = {
        items: 4,   
        slideBy: 1,
        autoplay: true,
        autoplayTimeout: 2000,
        controls: false,
        nav: false,
        autoplayButtonOutput: false,
        responsive: {
            0: { 
                items: 2,   
            },
            768: {  
                items: 1,   
            },
            1024: {   
                items: 4,
            },
        },
    };
    
    const settingsss3 = {
        items: 4,   
        slideBy: 1,
        autoplay: true,
        autoplayTimeout: 2000,
        controls: false,
        nav: false,
        autoplayButtonOutput: false,
        responsive: {
            0: { 
                items: 2,   
            },
            768: {  
                items: 3,   
            },
            1024: {   
                items: 4,
            },
        },
    };
    
    const settingsss555 = {
        items: 4,   
        slideBy: 1,
        autoplay: true,
        autoplayTimeout: 2000,
        controls: false,
        nav: false,
        autoplayButtonOutput: false,
        responsive: {
            0: { 
                items: 2,   
            },
            768: {  
                items: 1,   
            },
            1024: {   
                items: 2,
            },
        },
    };
    const settingsss5556 = {
        items: 4,   
        slideBy: 1,
        autoplay: true,
        autoplayTimeout: 2000,
        controls: false,
        nav: false,
        autoplayButtonOutput: false,
        responsive: {
            0: { 
                items: 1,   
            },
            768: {  
                items: 1,   
            },
            1024: {   
                items: 2,
            },
        },
    };
    const settingsss5557 = {
        items: 4,   
        slideBy: 1,
        autoplay: true,
        autoplayTimeout: 2000,
        controls: false,
        nav: false,
        autoplayButtonOutput: false,
        responsive: {
            0: { 
                items: 1,   
            },
            768: {  
                items: 1,   
            },
            1024: {   
                items: 2,
            },
        },
    };
    const settingmain2 = {
        items: 4,   
        slideBy: 1,
        autoplay: true,
        autoplayTimeout: 2000,
        controls: false,
        nav: false,
        autoplayButtonOutput: false,
        responsive: {
            0: {  
                items: 2,   
            },
            768: {  
                items: 1,   
            },
            1024: {   
                items: 2    ,
            },
        },
    };
    const settingmain3 = {
        items: 4,   
        slideBy: 1,
        autoplay: true,
        autoplayTimeout: 2000,
        controls: false,
        nav: false,
        autoplayButtonOutput: false,
        responsive: {
            0: {  
                items: 1,   
            },
             768: {  
                items: 1,   
            },
            1024: {   
                items: 1    ,
            },
        },
    };
    const settingmain = {
        items: 4,   
        slideBy: 1,
        autoplay: true,
        autoplayTimeout: 20000,
        controls: false,
        nav: false,
        autoplayButtonOutput: false,
        responsive: {
            0: { 
                items: 2,   
            },
           
            768: {  
                items: 2,   
            },
            1024: {   
                items: 4,
            },
        },
    };
    
    
    const getCity = async () => {
        setloading(true);
        try {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };

            const response = await fetch(
                "https://newapi.jaishreeramhealthcareprivateltd.com/cityRoute/cities",
                requestOptions
            );
            const result = await response.json();
            setCity(result.cities);
            // console.log(result.cities._id, "kkkk")
        } catch (error) {
            console.log(error);
        } finally {
            setloading(false);
        }
    };

    const handleCityChange = (selectedCity) => {
        if (selectedCity) {
          console.log("Selected city:", selectedCity); // Debugging log
          setSelectedCity(selectedCity); // Update the state with the selected city
        } else {
          setSelectedCity(null); // Reset state if no city is selected
        }
      };
      
    
    useEffect(() => {
        const savedCity = localStorage.getItem("selectedCity");
        if (savedCity) {
            setSelectedCity(JSON.parse(savedCity));
        }
    }, []);


    const defaultCity = city.length > 0 
  ? city.find((cityItem) => cityItem.name === "Jaipur") || city[0]
  : { _id: "default", name: "Jaipur" };


    // Find "Jaipur" in the city array or fallback to the first city
    const [currentCity, setCurrentCity] = useState(() => {
        const savedCity = localStorage.getItem("selectedCity");
        return savedCity
          ? JSON.parse(savedCity)
          : defaultCity;
      });
    
      useEffect(() => {
        if (currentCity) {
          localStorage.setItem("selectedCity", JSON.stringify(currentCity));
        }
      }, [currentCity]);
    
      const options = city.map((cityItem) => ({
        value: cityItem._id,
        label: cityItem.name,
        icon: <FaMapMarkerAlt style={{ marginRight: "8px" }} />,
      }));
    
      const customSingleValue = ({ data }) => (
        <div style={{ display: "flex", alignItems: "center", marginTop:'-28px' }}>
          {data.icon}
          <FaMapMarkerAlt className="fs-5 me-2"   />
          {data.label}
        </div>
      );
    
      const customOption = (props) => {
        const { data, innerRef, innerProps } = props;
        return (
          <div
            ref={innerRef}
            {...innerProps}
            style={{
              display: "flex",
              alignItems: "center",
              padding: "10px",
              cursor: "pointer",
            }}
          >
            {data.icon}
            {data.label}
          </div>
        );
      };
    
      const handleChange = (selectedOption) => {
        if (selectedOption) {
          setCurrentCity({ id: selectedOption.value, name: selectedOption.label });
        }
      };
    
      if (!city || city.length === 0) {
        return <div  style={{position:'absolute', top:'40%', left:'40% '}}>
            <h1> Loading ...</h1></div>;
      }
    
    return (

        <div style={{ overflowX: "hidden" }}>
            <div>
                <ToastContainer />
            </div>
            {/*<div className="cardcard">
        <div className="card-contentcard">
          <h2>Card Title</h2>
          <p>This is a card with a 4:1 aspect ratio.</p>
        </div>
      </div>*/}

            <section className="navbar-section" style={{ position: "sticky", top: 0, overflow: "hidden" }}>
                <Navbar />
            </section>

            <section style={{ marginBottom: 30, marginTop: 100 }}>
                <div className="container-fluid pt-4  mblview">
                    <div className="slider-container">

                      
                      <div className="container-fluid ">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 px-0">
                                <img src={top} style={{width:'100%'}}/>
                            </div>
                        </div>
                      </div>


                        {/* <Slider {...firstslide}>
                            <div className="slider-item">
                                <img src={baner} style={{ objectFit: "fill" }} className="slider-img w-100" />
                            </div>

                            <div className="slider-item">
                                <img src={malthora} style={{ objectFit: "fill" }} className="slider-img" />
                            </div>
                            <div className="slider-item">
                                <img src={baner} style={{ objectFit: "fill" }} className="slider-img" />
                            </div>
                        </Slider> */}
                    </div>
                </div>
            </section>

            <section>
                <div className="container">
                    <div className="row pt-5 expolore">


                    <div className="col-lg-7 col-md-7 col-sm-12"></div>
            <div className="col-lg-5 col-sm-12 my-3">
                <div>

                <div style={{ width: "100%" ,  }}> 
              <Select
      options={options}
      value={
        currentCity
          ? { value: currentCity.id, label: currentCity.name }
          : null
      }
      onChange={handleChange}
      placeholder="Please select a city"
      isSearchable
      components={{ SingleValue: customSingleValue, Option: customOption }}
      styles={{
        control: (base, state) => ({
          ...base,
          border: "1px solid #ccc",
          boxShadow: state.isFocused ? "0 0 0 2px #007bff" : "none",
          "&:hover": {
            border: "1px solid #007bff",
          },
        }),
      }}
    />
    </div>
                </div>
            </div>
                

    
                        {/* <div className="pb-3 " style={{ display: "flex", justifyContent: "flex-end" }}>
                            <div className="abcvv" style={{ display: "flex", alignItems: "center", gap: "40px", padding: "7px", border: "1px solid pink", borderRadius: 5 }}>
                                <img src={locations} style={{ height: 20, width: 20, marginLeft: "10px", }} />
                                <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    style={{ width: "100%", border: "none", listStyle: "none", boxShadow:'none' }}
                                    onChange={handleCityChange}
                                    value={selectedCity?.id || ""}
                                >
                                    <option value="" disabled>
                                        Please select a city
                                    </option>
                                    {city.map((cityItem, index) => (
                                        <option key={index} value={cityItem._id}>
                                            {cityItem.name}
                                        </option>
                                    ))}
                                </select> 



                            </div>
                        </div> */}
                        <div className="  top pe-5 pb-2 d-flex justify-content-between ">
                            <div>

                                <h3 style={{ fontFamily: "Inter", color: "#FF1493", fontWeight: "600" }}>Explore Categories</h3>
                            </div>

                            <div>

                                <p onClick={() => navigate("/CategeroyCards")} style={{ fontFamily: "Inter", color: "#FF1493", fontWeight: "600", textDecorationLine: "underline" }}>See All</p>
                            </div>

                        </div>


                        {
                            loading ? (
                                <BallTriangle
                                    height={50}
                                    width={50}
                                    radius={5}
                                    color="#4fa94d"
                                    ariaLabel="ball-triangle-loading"
                                    visible={true}
                                />
                            ) : (
                                data?.map((res) => (
                                  
                                    <button
                                        key={res._id}
                                        style={{ border: "none", background: "none" }}
                                        onClick={() => handleNavigation(res._id)} // Pass the category ID only
                                        className="col-lg-2 col-md-6 col-sm-6 col-6 mb-4"
                                    >
                                        <div
                                            style={{
                                                backgroundColor: "#FF1493",
                                                borderRadius: "10px",
                                                overflow: "hidden",
                                            }}
                                        >
                                            <div style={{ height: "130px", width: "100%" }}>
                                                <img
                                                    src={res.category_image}
                                                    style={{
                                                        height: "100%",
                                                        width: "100%",
                                                        objectFit: "cover",
                                                    }}
                                                />
                                            </div>
                                            <div style={{ backgroundColor: "#FF1493" }}>
                                                <p
                                                    className=" text-white text-nowrap p-2"
                                                    style={{ textAlign: "center" }}
                                                >
                                                    {res.category_name}
                                                </p>
                                            </div>
                                        </div>
                                    </button>
                                ))
                            )
                        }

                    </div>
                </div>
            </section>

            {/*     {/*In Spot Light */}
            <section className="pt-5 explore">
            <div className="top spot ps-5 pe-5">
                <h3 style={{ fontFamily: "Inter", color: "#FF1493", fontWeight: "600" }}>
                    In Spotlight
                </h3>
            </div>
            <div className="container-fluid ex p-5"  >
                <div className="slider-container">
                    {loading ? (
                        <BallTriangle
                            height={50}
                            width={50}
                            radius={5}
                            color="#4fa94d"
                            ariaLabel="ball-triangle-loading"
                            visible={true}
                        />
                    ) : (
                        Array.isArray(Banners[0]?.sizes[1]?.images) &&
                        Banners[0].sizes[1].images.length > 0 ? ( // Check if the images array exists
                            <TinySlider settings={settingss1}>
                                {Banners[0].sizes[1].images.map((res) => (
                                    <div key={res.id} className="col-lg-12">
                                        <div
                                            onClick={() => window.open(res.url)}
                                            className="data"
                                            style={{
                                                backgroundSize: "cover",
                                                overflow: "hidden",
                                                // height: 400,
                                                borderRadius: 10,
                                                backgroundRepeat: "no-repeat",
                                                border: "2px solid pink",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <img
                                                src={res.imageUrl}
                                                alt={res.name}
                                                style={{
                                                    height: "100%",  width: "100%",
                                                    objectFit: "cover",
                                                    borderRadius: 10,
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </TinySlider>
                        ) : (
                            <p style={{ textAlign: "center", color: "white" }}>
                                No banners available.
                            </p>
                        )
                    )}
                </div>
            </div>
        </section>
        

            <section>
                <div className="container-fluid topimg ps-4 pe-4">
                    <div className="row">
                        <div className="top spot ps-5 pt-5">
                            <h3 style={{ fontFamily: "Inter", color: "#FF1493", fontWeight: "600" }}>
                                Top Doctors
                            </h3>
                        </div>
                        <div className="col-lg-12 col-sm-12 col-md-12">
                            {/* <div className="slider-container pt-0 mt-0">
                                <TinySlider settings={settingssBest12}>
                                    {Banners[3]?.sizes[1]?.images?.length > 0 ? (
                                        Banners[3].sizes[1].images.map((slide, index) => (
                                            <div key={index} className="col-lg-12 col-5">
                                                <div  onClick={() => window.open(slide.url)}

                                                    className="data"
                                                    style={{
                                                        backgroundSize: "cover", 
                                                        overflow: "hidden",
                                                        borderRadius: 10,
                                                        backgroundRepeat: "no-repeat",
                                                        width: "100%",
                                                        border: "2px solid pink",
                                                        cursor:"pointer"
                                                    }}
                                                >
                                                    <img
                                                        src={slide.imageUrl}
                                                        style={{
                                                            height: "100%",
                                                            width: "100%",
                                                            objectFit: "cover",
                                                            borderRadius: 10,
                                                            overflow: "hidden",
                                                        }}
                                                        alt={`Slide ${index}`}
                                                    />
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div></div>
                                    )}
                                </TinySlider>
                            </div> */}

                        </div>
                    </div>
                </div>
            </section>

            {/* Best Offers Small */}
            <section>
                <div className="container-fluid">
                    {/* <div className="row best p-3">
                        <TinySlider settings={settingsss}>
                            {Banners[3]?.sizes[0]?.images?.length > 0 ? (
                                Banners[3].sizes[0].images.map((slide, index) => (
                                    <div
                                        onClick={() => window.open(slide.url)}

                                        key={index}
                                        className="topplas col-lg-5 col-sm-12 col-md-6 col-5"
                                        style={{
                                            // height: 270,
                                            backgroundSize: "cover",
                                            overflow: "hidden",
                                            padding: "10px",
                                            cursor:"pointer"
                                        }}
                                    >
                                        <img
                                            src={slide.imageUrl}
                                            style={{
                                                height: "100%",
                                                width: "100%",
                                                border: "2px solid #FF1493",
                                                borderRadius: 5,
                                                objectFit: "cover",
                                            }}
                                            alt={`Slide ${index}`}
                                        />
                                    </div>
                                ))
                            ) : (
                                <div></div>
                            )}
                        </TinySlider>
                    </div> */}

                </div>
            </section>


            {/* Best Offers Spot */}
            <section>
                <div className="container-fluid">
                    <div className="row best p-3 pb-0"> 
                        <TinySlider settings={settingssBest12}>
                            {Banners?.[3]?.sizes?.[3]?.images?.length > 0 ? (
                                Banners[3].sizes[3].images?.map((slide, index) => (
                                    <div
                                        onClick={() => window.open(slide.url)}

                                        key={index}
                                        className="topplas col-lg-5 col-sm-12 col-md-6 col-5"
                                        style={{
                                            // height: 200,
                                            backgroundSize: "cover",
                                            overflow: "hidden",
                                            padding: "10px",
                                            cursor:"pointer"
                                        }}
                                    >
                                        <img
                                            src={slide.imageUrl}
                                            alt={`Slide ${index}`}
                                            style={{
                                                height: "100%",
                                                width: "100%",
                                                objectFit: "cover",
                                                borderRadius: 5,
                                                border: "2px solid #FF1493",
                                            }}
                                        />
                                    </div>
                                ))
                            ) : (
                                <div></div>
                            )}
                        </TinySlider>
                    </div>

                </div>
            </section>


            {/* Best Offers Mid */}
            {/* <section>
                <div className="container-fluid">
                    <div className="row best p-3">
                        <TinySlider settings={settingsss}>
                            {Banners?.[3]?.sizes?.[2]?.images?.length > 0 ? (
                                Banners[3].sizes[2].images.map((slide, index) => (
                                    <div
                                        onClick={() => window.open(slide.url)}

                                        key={index}
                                        className="topplas col-lg-5 col-sm-12 col-md-6 col-5"
                                        style={{
                                            height: 220,
                                            backgroundSize: "cover",
                                            overflow: "hidden",
                                            padding: "10px",
                                            cursor:"pointer"
                                        }}
                                    >
                                        <img
                                            src={slide.imageUrl}
                                            alt={`Slide ${index}`}
                                            style={{
                                                height: "100%",
                                                width: "100%",
                                                objectFit: "cover",
                                                border: "2px solid #FF1493",
                                                borderRadius: 5,
                                            }}
                                        />
                                    </div>
                                ))
                            ) : (
                                <div></div>
                            )}
                        </TinySlider>
                    </div>

                </div>
            </section> */}

            <section>
                <div className="container-fluid topimg ps-4 pe-4">
                    <div className="row">
                        <div className="top spot ps-5">
                            <h3 className="py-5 oldman" style={{ fontFamily: "Inter", color: "#FF1493", fontWeight: "600" }}>
                                Top Hospitals
                            </h3>
                        </div>
                        {/* <div className="col-lg-12 pt-4 col-sm-12 col-md-12">
                            <div className="slider-container">  
                                <TinySlider settings={settingssBest}>
                                    {Banners[4]?.sizes[1]?.images?.length > 0 ? (
                                        Banners[4].sizes[1].images.map((slide, index) => (
                                            <div key={index} className="col-lg-12 col-5">
                                                <div
                                                    onClick={() => window.open(slide.url)}

                                                    className="data"
                                                    style={{
                                                        backgroundSize: "cover",
                                                        height: 400,
                                                        overflow: "hidden",
                                                        borderRadius: 10,
                                                        backgroundRepeat: "no-repeat",
                                                        width: "100%",
                                                        border: "2px solid pink",
                                                        cursor:"pointer"
                                                    }}
                                                >
                                                    <img
                                                        src={slide.imageUrl}
                                                        style={{
                                                            height: "100%",
                                                            width: "100%",
                                                            objectFit: "cover",
                                                            borderRadius: 10,
                                                            overflow: "hidden",
                                                        }}
                                                        alt={`Slide ${index}`}
                                                    />
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div></div>
                                    )}
                                </TinySlider>
                            </div>

                        </div> */}
                    </div>
                </div>
            </section>
            {/* Best Offers Small */}
            <section>
                <div className="container-fluid">
                    {/* <div className="row best p-3">
                        <TinySlider settings={settingsss}>
                            {Banners[4]?.sizes[0]?.images?.length > 0 ? (
                                Banners[4].sizes[0].images.map((slide, index) => (
                                    <div
                                        onClick={() => window.open(slide.url)}

                                        key={index}
                                        className="topplas col-lg-5 col-sm-12 col-md-6 col-5"
                                        style={{
                                            // height: 270,
                                            backgroundSize: "cover",
                                            overflow: "hidden",
                                            padding: "10px",
                                            cursor: "pointer"
                                        }}
                                    >
                                        <img
                                            src={slide.imageUrl}
                                            style={{
                                                height: "100%",
                                                width: "100%",
                                                border: "2px solid #FF1493",
                                                borderRadius: 5,
                                                objectFit: "cover",
                                            }}
                                            alt={`Slide ${index}`}
                                        />  
                                    </div>
                                ))
                            ) : (
                                <div></div>
                            )}
                        </TinySlider>
                    </div> */}

                </div>
            </section>


            {/* Best Offers Spot */}
            <section>
                <div className="container-fluid">
                    <div className="row best p-3"> 
                        <TinySlider settings={settingssBest12}>
                            {Banners?.[4]?.sizes?.[3]?.images?.length > 0 ? (
                                Banners[4].sizes[3].images.map((slide, index) => (
                                    <div
                                    key={index}
                                    className="topplas col-lg-5 col-sm-12 col-md-6 col-5"
                                    onClick={() => window.open(slide.url)}
                                    style={{
                                        height: "auto", // Ensures consistent height
                                        backgroundSize: "cover",
                                        overflow: "hidden",
                                        padding: "10px",
                                        cursor: "pointer",
                                    }}
                                >
                                    <img
                                        src={slide.imageUrl}
                                        alt={`Slide ${index}`}
                                        style={{
                                            height: "100%", // Takes full height of the container
                                            width: "100%", // Stretches to full width
                                            objectFit: "cover", // Ensures the full image is visible without cropping
                                            borderRadius: 5, padding:'0% ',
                                            border: "2px solid #FF1493",
                                           
                                        }}
                                    />
                                </div>
                                
                                ))
                            ) : (
                                <div></div>
                            )}
                        </TinySlider>
                    </div>

                </div>
            </section>


            {/* Best Offers Mid */}
            {/* <section>
                <div className="container-fluid">
                    <div className="row best p-3">
                        <TinySlider settings={settingsss}>
                            {Banners?.[4]?.sizes?.[2]?.images?.length > 0 ? (
                                Banners[4].sizes[2].images.map((slide, index) => (
                                    <div
                                        onClick={() => window.open(slide.url)}

                                        key={index}
                                        className="topplas col-lg-5 col-sm-12 col-md-6 col-5"
                                        style={{
                                            // height: 220,
                                            backgroundSize: "cover",
                                            overflow: "hidden",
                                            padding: "10px",
                                            cursor: "pointer"
                                        }}
                                    >
                                        <img
                                            src={slide.imageUrl}
                                            alt={`Slide ${index}`}
                                            style={{
                                                height: "100px",
                                                width: "100%",
                                                objectFit: "cover",
                                                border: "2px solid #FF1493",
                                                borderRadius: 5,
                                            }}
                                        />
                                    </div>
                                ))
                            ) : (
                                <div></div>
                            )}
                        </TinySlider>
                    </div>

                </div>
            </section> */}
            <section>
                {/* Top Plans */}
                <div className="top pt-5 spot ps-5">
                    <h3 style={{ fontFamily: "Inter", color: "#FF1493", fontWeight: "600" }}>Top Plans </h3>
                </div>
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <TinySlider settings={settingmain3}>
                            { 
                                Banners?.[0]?.sizes?.[1]?.images?.length > 0 ? (
                                    Banners[0].sizes[1].images.map((res, index) => (
                                        <div
                                            onClick={() => window.open(res.url)}

                                            key={index}
                                            className="col-md-6 col-sm-12 p-2 col-12 topplas"
                                            style={{
                                                // height: 300,
                                                backgroundSize: "cover",
                                                overflow: "hidden",
                                                cursor: "pointer"
                                            }}
                                        >
                                            <img
                                                src={res.imageUrl}
                                                alt={`Banner ${index}`}
                                                style={{
                                                    height: "100%",
                                                    width: "100%",
                                                    // objectFit: "cover",
                                                    borderRadius: 10,
                                                    border: "2px solid #FF0596",
                                                }}
                                            />
                                        </div>
                                    ))
                                ) : (
                                    <div></div>
                                )
                            }
                        </TinySlider>
                    </div>
                </div>

            </section>



            {/* Top Plans Spot */}
            <section>
                <div className="container-fluid">
                    <div className="row best pt-5 pb-3 ">
                        <div className="slider-container">   
                            <TinySlider settings={settingmain}  className="h-100"> 
                                {Banners?.[1]?.sizes?.[3]?.images?.length > 0 ? (
                                    Banners[1].sizes[3].images.map((slide, index) => (
                                        <div
                                            onClick={() => window.open(slide.url)}

                                            key={index}
                                            className="col-lg-5 col-sm-12 col-md-6 col-5 topplas"
                                            style={{
                                                // height: 200,
                                                backgroundSize: "cover",
                                                overflow: "hidden",
                                                padding: "10px",
                                                cursor: "pointer"
                                            }}
                                        >
                                            <img
                                                src={slide.imageUrl}
                                                alt={`Slide ${index}`}
                                                style={{
                                                    height: "50vh",
                                                    width: "100%",
                                                    objectFit: "cover",
                                                    borderRadius: 5,
                                                    border: "2px solid #FF1493",
                                                }}  className="maya"
                                            />
                                        </div>
                                    ))
                                ) : (
                                    <div></div>
                                )}
                            </TinySlider>

                        </div>
                    </div>
                </div>
            </section>



            {/* Top Plans Mid */}
            <section>
                <div className="container-fluid">
                    <div className="row best">
                        <div className="slider-container"> 
                            <TinySlider settings={settingsss1}>
                                {Banners?.[1]?.sizes?.[2]?.images?.length > 0 ? (
                                    Banners[1].sizes[2].images.map((slide, index) => (
                                        <div key={index} className="card1">
                                            <div
                                                onClick={() => window.open(slide.url)}

                                                className="topplas"
                                                style={{
                                                    // height: 270,
                                                    backgroundSize: "cover",
                                                    overflow: "hidden",
                                                    borderRadius: 10,
                                                    border: "2px solid pink",
                                                    cursor: "pointer"
                                                }}
                                            >
                                                <img
                                                    src={slide.imageUrl}
                                                    alt={`Slide ${index}`}
                                                    style={{
                                                        width: "100%",
                                                        objectFit: "cover",
                                                        height: "100%",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div></div>
                                )}
                            </TinySlider>
                        </div>

                    </div>
                </div>
            </section>

            {/* Top Plans Small */}
            <section>
                <div className="container-fluid">
                    <div className="row best pt-5 pb-3">
                        <div className="slider-container"> 
                            <TinySlider settings={settingsss5556}>
                                {Banners?.[1]?.sizes?.[0]?.images?.length > 0 ? (
                                    Banners[1].sizes[0].images.map((slide, index) => (
                                        <div key={index} className="col-lg-5 col-5">
                                            <div
                                                onClick={() => window.open(slide.url)}

                                                className="data"
                                                style={{
                                                    backgroundSize: "cover",
                                                    // height: 400,
                                                    overflow: "hidden",
                                                    borderRadius: 10,
                                                    backgroundRepeat: "no-repeat",
                                                    width: "100%",
                                                    cursor: "pointer"
                                                }}
                                            >
                                                <img
                                                    src={slide.imageUrl}
                                                    alt={`Slide ${index}`}
                                                    style={{
                                                        height: "100%",
                                                        width: "100%",
                                                        objectFit: "cover",
                                                        borderRadius: 10,
                                                        overflow: "hidden",
                                                        padding: 10
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div></div>
                                )}
                            </TinySlider>
                        </div>

                    </div>
                </div>
            </section>


          


            {/* Best Offers */}
            <section>
                <div className="container-fluid topimg ps-4 pe-4 py-4">
                    <div className="row">
                        <div className="top spot ps-5">
                            <h3 style={{ fontFamily: "Inter", color: "#FF1493", fontWeight: "600" }}>
                                Best Offers
                            </h3>
                        </div>
                        <div className="col-lg-12 pt-4 col-sm-12 col-md-12">
                            <div className="slider-container">  
                                <TinySlider settings={settingssBest}>
                                    {Banners[2]?.sizes[1]?.images?.length > 0 ? (
                                        Banners[2].sizes[1].images.map((slide, index) => (
                                            <div key={index} className="col-lg-12 col-5">
                                                <div
                                                    onClick={() => window.open(slide.url)}

                                                    className="data"
                                                    style={{
                                                        backgroundSize: "cover",
                                                        // height: 400,
                                                        overflow: "hidden",
                                                        borderRadius: 10,
                                                        backgroundRepeat: "no-repeat",
                                                        width: "100%",
                                                        // border: "2px solid pink",
                                                        cursor: "pointer"
                                                    }}
                                                >
                                                    <img
                                                        src={slide.imageUrl}
                                                        style={{
                                                            height: "auto",
                                                            width: "100%",
                                                            objectFit: "contain",
                                                            borderRadius: 10,
                                                            overflow: "hidden",
                                                        }}
                                                        alt={`Slide ${index}`}
                                                    />
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div></div>
                                    )}
                                </TinySlider>
                            </div>

                        </div>
                    </div>
                </div>
            </section>


            {/* <section className="pt-5">
                <div className="container-fluid  topimg pt-5 pb-5 p-3">
                    <img src={pro} style={{ height: "100%", width: "100%" }} />
                </div>
            </section> */}

            

            {/* Best Offers Small */}
            <section>
                <div className="container-fluid">
                    <div className="row best p-3">    
                        <TinySlider settings={settingsss5557}> 
                            {Banners[2]?.sizes[0]?.images?.length > 0 ? (
                                Banners[2].sizes[0].images?.map((slide, index) => (
                                    <div
                                        onClick={() => window.open(slide.url)}

                                        key={index}
                                        className="topplas col-lg-5 col-sm-12 col-md-6 col-5"
                                        style={{ 
                                            backgroundSize: "contain",
                                            overflow: "hidden",
                                            padding: "10px",
                                            cursor: "pointer"
                                        }}
                                    >

                                        {/* <div  style={{
        backgroundImage: `url(${slide.imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center", backgroundPosition:'center', height:'100vh',
        backgroundRepeat: "no-repeat", 
        width: "100%",
    }}> </div> */}
    
                                        <img
                                            src={slide.imageUrl}
                                            style={{
                                                // height: "33vh",
                                                width: "100%",
                                                border: "2px solid #FF1493",
                                                borderRadius: 5,
                                                objectFit: "cover",
                                            }} 
                                            alt={`Slide ${index}`}
                                        />
                                    </div>
                                ))
                            ) : (
                                <div></div>
                            )}
                        </TinySlider>
                    </div>

                </div>
            </section>


            {/* Best Offers Spot */}
            <section>
                <div className="container-fluid">
                    <div className="row best p-3"> 
                        <TinySlider settings={settingsss2}>
                            {Banners?.[2]?.sizes?.[3]?.images?.length > 0 ? (
                                Banners[2].sizes[3].images.map((slide, index) => (
                                    <div
                                        onClick={() => window.open(slide.url)}

                                        key={index}
                                        className="topplas col-lg-5 col-sm-12 col-md-6 col-5"
                                        style={{
                                            // height: 200,
                                            backgroundSize: "cover",
                                            overflow: "hidden",
                                            padding: "10px",
                                            cursor: "pointer"
                                        }}
                                    >
                                        <img
                                            src={slide.imageUrl}
                                            alt={`Slide ${index}`}
                                            style={{
                                                height: "50vh",
                                                width: "100%",
                                                objectFit: "cover",
                                                borderRadius: 5,
                                                border: "2px solid #FF1493",
                                            }} className="maya"
                                        />
                                    </div>
                                ))
                            ) : (
                                <div></div>
                            )}
                        </TinySlider>
                    </div>

                </div>
            </section>


            {/* Best Offers Mid */}
            <section>
                <div className="container-fluid">
                    <div className="row best p-3">   
                        <TinySlider settings={settingsss3}> 
                            {Banners?.[2]?.sizes?.[2]?.images?.length > 0 ? (
                                Banners[2].sizes[2].images.map((slide, index) => (
                                    <div
                                        onClick={() => window.open(slide.url)}

                                        key={index}
                                        className="topplas col-lg-5 col-sm-12 col-md-6 col-5"
                                        style={{
                                         
                                            objectFit: "cover",
                                            overflow: "hidden",
                                            padding: "10px",
                                            cursor: "pointer", padding:'25px'
                                        }}
                                    >
                                        <img
                                            src={slide.imageUrl}
                                            alt={`Slide ${index}`}
                                            style={{
                                                //  height:'50vh',
                                                width: "100%",
                                                // objectFit: "contain",
                                                border: "2px solid #FF1493",
                                                borderRadius: 5,
                                                boxSizing:'border-box'
                                            }} className="maya"
                                        />
                                    </div>
                                ))
                            ) : (
                                <div></div>
                            )}
                        </TinySlider>
                    </div>

                </div>
            </section>


            <section className="pt-5  topimg">
                <div className="container">
                    <div className="row align-items-center">
                        {/* 50% for Heading and Description */}
                        <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                            <h2 style={{ color: "#FF1493", fontSizez: "36px", fontWeight: "bold" }}>Our Services</h2>
                            <p style={{ fontSize: "18px", color: "#333", marginTop: "20px" }}>
                                Explore the best healthcare services with top-notch facilities. Our dedicated team
                                ensures you get the best care possible.

                                
                            </p>
                        </div>

                        {/* 50% for Video */}
                        <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                            <video width="100%" height="300" controls >
                                <source src={video1} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </section>

  {/*Our Services1*/}
  {/* <section className="pt-5  topimg">
                <div className="container">
                    <div className="row align-items-center"> 
                        <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                            <h2 style={{ color: "#FF1493", fontSize: "36px", fontWeight: "bold" }}>Our Services kjnkljbkjlb</h2>
                            <p style={{ fontSize: "18px", color: "#333", marginTop: "20px" }}>
                                Explore the best healthcare services with top-notch facilities. Our dedicated team
                                ensures you get the best care possible.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                            <video width="100%" height="300" controls >
                                <source src={video} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </section> */}





<section id="videos">
    <div className="container">
        <h1 className="my-4 py-3"  style={{ color: "#FF1493",}}> Videos List</h1>
        <div className="row">
            <div className="col-lg-12 col-md-6 col-sm-12">

                <VideoList/>
           
            </div>
        </div>
    </div>
</section>

            <a
                href="https://wa.me/9653778555"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    backgroundColor: '#25D366',
                    color: 'white',
                    borderRadius: '50%',
                    width: '60px',
                    height: '60px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1000,
                }}
            >
                <FaWhatsapp size={30} />
            </a>
            {/* Footer Section */}
            <section className="mt-5">
                <Footer />
            </section>
        </div>
    );
}

export default Home;