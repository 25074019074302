import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useToken } from '../store';

const ProfileUpdate = () => {
  const Navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [mobile, setMobile] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [address, setAddress] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [profession, setProfession] = useState("");
  const [hasInsurance, setHasInsurance] = useState("");
  const [insuranceType, setInsuranceType] = useState("");
  const [insuranceCompany, setInsuranceCompany] = useState("");
  const [loading, SetLoading] = useState(false);
  const [profileUpdated, setProfileUpdated] = useState(false); // Track profile update status
  const { token, setToken } = useToken(state => state)

  // const [mobilen, setMobilen] = useState("");
  // console.log(mobile, "asdfghjkl")
  useEffect(() => {
    const storedMobile = localStorage.getItem("mobile");
    if (storedMobile) {
      setMobile(storedMobile); // Set mobile state
    }
  }, []);

  async function getprofile() {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);


      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };
      // console.log(mobile,"kal")
      fetch(`https://newapi.jaishreeramhealthcareprivateltd.com/users/getProfile/${mobile}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.success === true) {
            setAddress(result.user.address)
            setAge(result.user.age)
            setBloodGroup(result.user.bloodGroup)
            setEmail(result.user.email)
            setProfession(result.user.profession)
            setInsuranceType(result.user.insuranceType)
            setInsuranceCompany(result.user.insuranceCompany)
            setHasInsurance(result.user.hasInsurance)
            setFullName(result.user.fullName)
            setMobile(result.user.mobile)
            setGender(result.user.gender)
          }
        })
    } catch (error) {
      console.log(error)
    }
  }
  async function profileup(e) {
    e.preventDefault(); // Prevent form default submission
    SetLoading(true);
    try {
      const myHeaders = new Headers();

      myHeaders.append("Authorization", `Bearer ${token}`);

      const formdata = new FormData();
      formdata.append("email", email);
      formdata.append("fullName", fullName);
      formdata.append("mobile", mobile);
      formdata.append("gender", gender);
      formdata.append("age", age);
      formdata.append("address", address);
      formdata.append("bloodGroup", bloodGroup);
      formdata.append("profession", profession);
      formdata.append("hasInsurance", hasInsurance);
      formdata.append("insuranceType", insuranceType);
      formdata.append("insuranceCompany", insuranceCompany);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow"
      };

      const response = await fetch("https://newapi.jaishreeramhealthcareprivateltd.com/users/updateProfile", requestOptions);
      const result = await response.json();

      if (result.success === true) {
        toast.success(result.message, { autoClose: 3000 });
        setProfileUpdated(true); // Set profile update status
      } else {
        toast.error(result.message || "Error updating profile");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while updating profile.");
    } finally {
      SetLoading(false);
    }
  }


  // useEffect to navigate after profile is updated
  useEffect(() => {
    if (profileUpdated) {
      Navigate("/home"); // Navigate only after profile update
      setProfileUpdated(false); // Prevent navigation re-trigger
    }
  }, [profileUpdated, Navigate]); // Run when profileUpdated changes

  useEffect(() => {
    if (mobile) {
      getprofile();
    }
  }, [mobile]);

  return (
    <>
      <ToastContainer />
      <Box sx={{ maxWidth: 600, margin: '0 auto', padding: 3, marginTop: "20px" }}>
        <h2 style={{ textAlign: "center", color: "#FF0596", fontWeight: "700" }}>Update Profile</h2>
        <form onSubmit={profileup}>
          <Grid container spacing={2}>
            {/* Email */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>

            {/* Full Name */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Full Name"
                name="fullName"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </Grid>

            {/* Mobile */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Mobile"
                name="mobile"
                type="tel"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </Grid>

            {/* Gender */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Gender</InputLabel>
                <Select
                  name="gender"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  label="Gender"
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Age */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Age"
                name="age"
                type="number"
                value={age}
                onChange={(e) => setAge(e.target.value)}
              />
            </Grid>

            {/* Address */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address"
                name="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Grid>

            {/* Blood Group */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Blood Group"
                name="bloodGroup"
                value={bloodGroup}
                onChange={(e) => setBloodGroup(e.target.value)}
              />
            </Grid>

            {/* Profession */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Profession"
                name="profession"
                value={profession}
                onChange={(e) => setProfession(e.target.value)}
              />
            </Grid>

            {/* Insurance */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Has Insurance</InputLabel>
                <Select
                  name="hasInsurance"
                  value={hasInsurance}
                  onChange={(e) => setHasInsurance(e.target.value)}
                  label="Has Insurance"
                >
                  <MenuItem value="YES">Yes</MenuItem>
                  <MenuItem value="NO">No</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Insurance Type */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Insurance Type"
                name="insuranceType"
                value={insuranceType}
                onChange={(e) => setInsuranceType(e.target.value)}
              />
            </Grid>

            {/* Insurance Company */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Insurance Company"
                name="insuranceCompany"
                value={insuranceCompany}
                onChange={(e) => setInsuranceCompany(e.target.value)}
              />
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{ backgroundColor: '#FF0596' }}
                disabled={loading} // Disable the button when loading
              >
                {loading ? "Updating Profile..." : "Update Profile"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default ProfileUpdate;
