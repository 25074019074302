import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Landing from './Compontes/Landing';
import LoginScreen from './Compontes/LoginScreen';
import LoginOtp from './Compontes/LoginOtp';
import Home from './Compontes/MainCom/Home';
import DroctotCat from './Compontes/MainCom/DroctotCat';
import Hospitlas from './Compontes/MainCom/Hospitlas';
import CategeroyCards from './Compontes/MainCom/CategeroyCards';
import SignUp from './Compontes/SignUp';
import Plans from './Compontes/MainCom/Plans';
import { useToken } from './store';
import ProfileUpdate from './Compontes/Profile';
import Terms from './Compontes/MainCom/Terms';
import PrivacyPolicy from './Privcy';

function App() {

  const {token, setToken} = useToken(state=>state)
  console.log(token)
  const isAuth = token;
  // Example authentication check from Redux (or any state management)
  console.log(localStorage.getItem("token"), isAuth, 'isAuth=====>');

  // Component to protect routes
  const ProtectedRoute = ({ element }) => {
    return isAuth ? element : <Navigate to="/" replace />;
  };

  // Component to handle unauthenticated routes (like Login)
  const AuthRoute = ({ element }) => {
    return isAuth ? <Navigate to="/home" replace /> : element;
  };




  return (
    <Routes>
      {/* Public routes */}
      <Route
        path="/"
        element={isAuth ? <Navigate to="/home" replace /> : <Landing />}/>
      <Route path="/loginNumber" element={<AuthRoute element={<LoginScreen/>} />} />
      <Route path="/LoginOtp" element={<AuthRoute element={<LoginOtp />} />} />
      <Route path="/signup" element={<AuthRoute element={<SignUp />} />} />
      <Route path="/terms" element={<AuthRoute element={<Terms/>} />} />
      <Route path="/privacy" element={<AuthRoute element={<PrivacyPolicy/>} />} />
      {/* Protected routes */}
      <Route path="/home" element={<ProtectedRoute element={<Home />} />} />
      <Route path="/DroctotCat" element={<ProtectedRoute element={<DroctotCat />} />} />
      <Route path="/Hospitlas" element={<ProtectedRoute element={<Hospitlas />} />} />
      <Route path="/CategeroyCards" element={<ProtectedRoute element={<CategeroyCards />} />} />
      <Route path="/plans" element={<ProtectedRoute element={<Plans />} />} />
      <Route path="/ProfileUpdate" element={<ProtectedRoute element={<ProfileUpdate/>} />} />
     

      {/* Catch all undefined routes */}
      <Route path="*" element={<Navigate to={isAuth ? "/home" : "/"} />} />
    </Routes>
  );
}

export default App;