// import React, { useEffect, useState } from "react";
// import Navbar from "./Navbar";
// import Footer from "../Footer";
// import { BallTriangle } from "react-loader-spinner";
// import { useLocation } from "react-router-dom";
// import { Pagination } from "@mui/material";
// import { useToken } from "../../store";

// export default function Hospitals() {
//   const location = useLocation();
//   const { selectedCategoryId, selectedCityName } = location.state || {}; 
//   console.log(selectedCategoryId, selectedCityName ,"kajal")
//   const [data, setData] = useState([]);
//   const [category, setCategory] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [page, setPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(0);
//   const {token, setToken} = useToken(state=>state)
//   useEffect(() => {
//     fetchSubCategory();
//   }, [page]); // Fetch data whenever the page changes

//   const fetchSubCategory = async () => {
//     setLoading(true);
//     try {
//       const myHeaders = new Headers();
//       myHeaders.append("Authorization", `Bearer ${token} `);

//       const requestOptions = {
//         method: "GET",
//         headers: myHeaders,
//         redirect: "follow",
//       };

//       const response = await fetch(
//         `https://newapi.jaishreeramhealthcareprivateltd.com/category/subCategories/MainScreen?categoryID=${selectedCategoryId}f&${page}=1&limit=10&city=${selectedCityName}`,
//         requestOptions
//       );
//       const result = await response.json();

//       if (result.success) {
//         setData(result.data.items || []); // Assuming your API returns an array of items
//         setCategory(result.data.category || ""); // Adjust based on your API structure
//         setTotalPages(result.data.totalPages || 0); // Update totalPages based on your API response
//       } else {
//         setData([]); // Set to empty array if API indicates failure
//       }
//     } catch (error) {
//       console.log(error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handlePageChange = (event, value) => {
//     setPage(value);
//   };

//   return (
//     <div className="mb-0">
//       <section>
//         <Navbar />
//       </section>

//       <section>
//         <div className="container-fluid mb-0">
//           {/* Heading Section */}
//           <div className="text-center pt-5 pb-3">
//             <h1>{category}</h1>
//           </div>
//           <div className="row">
//             {loading ? (
//               <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
//                 <BallTriangle
//                   height={50}
//                   width={50}
//                   radius={5}
//                   color="#4fa94d"
//                   ariaLabel="ball-triangle-loading"
//                   visible={true}
//                 />
//               </div>
//             ) : data.length > 0 ? (
//               data.map((res) => (
//                 <div key={res.id} className="col-lg-4 col-sm-12 col-md-12 mt-4 mb-5">
//                   <div
//                     style={{
//                       height: "180px",
//                       width: "100%",
//                       backgroundSize: "cover",
//                       backgroundRepeat: "no-repeat",
//                     }}
//                   >
//                     <img
//                       src={res.url}
//                       alt={res.name}
//                       style={{
//                         height: "100%",
//                         width: "100%",
//                         objectFit: "cover",
//                         borderTopLeftRadius: "10px",
//                         borderTopRightRadius: "10px",
//                       }}
//                     />
//                   </div>
//                   <div
//                     style={{
//                       backgroundColor: "#faf5f5",
//                       boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
//                       borderBottomRightRadius: "10px",
//                       borderEndStartRadius: "10px",
//                       padding: "10px",
//                     }}
//                   >
//                     <h4>{res.name}</h4>
//                     <h6>Address:</h6>
//                     <p>{res.address}</p>
//                   </div>
//                 </div>
//               ))
//             ) : (
//               <div className="text-center mt-5">
//                 <h5>No data found</h5>
//               </div>
//             )}
//           </div>
//         </div>
//       </section>
// <div style={{display:"flex",justifyContent:"center"}}>
// <Pagination 
//         count={totalPages} 
//         page={page} 
//         onChange={handlePageChange} 
//         variant="outlined" 
//         shape="rounded"
//         color="primary"
//       />
// </div>
      

//       <section>
//         <Footer/>
//       </section>
//     </div>
//   );
// }


import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "../Footer";
import { BallTriangle } from "react-loader-spinner";
import { useLocation } from "react-router-dom";
import { Pagination } from "@mui/material";
import { useToken } from "../../store";
import { FaWhatsapp } from "react-icons/fa";
import { VscCallOutgoing } from "react-icons/vsc";
import '../../App.css'
export default function Hospitals() {
  const location = useLocation();
  const { selectedCategoryId, selectedCity } = location.state;
  console.log(selectedCategoryId, selectedCity);
  
  const [data, setData] = useState([]);
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { token, setToken } = useToken(state => state);

  useEffect(() => {
    fetchSubCategory();
  }, [page]); // Fetch data whenever the page changes

  const fetchSubCategory = async () => {
    setLoading(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token} `);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      console.log(selectedCategoryId,selectedCity,"llllll")

      const response = await fetch(
        `https://newapi.jaishreeramhealthcareprivateltd.com/category/subCategories/MainScreen?categoryID=${selectedCategoryId}&page=1&limit=10&city=${selectedCity}`,
        requestOptions
      );
      const result = await response.json();
      console.log(result)
      if (result.success===true) {
        console.log(result,"ccvv")
        setData(result.data || []); // Assuming your API returns an array of items
        setCategory(result.data.category || ""); // Adjust based on your API structure
        setTotalPages(result.data.totalPages || 0); // Update totalPages based on your API response
      } else {
        setData([]); // Set to empty array if API indicates failure
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <section>
        <Navbar />
      </section>

      <section style={{ flexGrow: 1 }}>
        <div className="container-fluid mb-0">
          {/* Heading Section */}
          <div className="text-center pt-5 pb-3">
            <h1>{category}</h1>
          </div>
          <div className="row">
            {loading ? (
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <BallTriangle
                  height={50}
                  width={50}
                  radius={5}
                  color="#4fa94d"
                  ariaLabel="ball-triangle-loading"
                  visible={true}
                />
              </div>
            ) : data.length > 0 ? (
              data.map((res) => (
                <div onClick={() => window.open(res.url)} key={res.id} className="col-lg-4 col-sm-12 col-md-12  mt-5 mb-5" >


                  <div className="card  babaji" style={{border:'1px solid gray'}}>
                    <div className="row">
                      <div className="col-lg-5 col-sm-12 py-0">
                      <img  src={res.subCategory_image}  alt={res.name}
                      style={{     width: "100%", height:'28vh', 
                         }}/>
                      </div>
                      <div className="col-lg-7 col-sm-12">
                      <h5 className="mb-0">{res.name} </h5>
                    <p className="d-block text-truncate " style={{  wordBreak: 'break-word'}}>{res.address}</p>
                    <p>{res.designation}</p>
                    <p>{res.city}</p>
                    {/* <p>{res.whatsapp_number}</p> */}

                    <div style={{display:'flex', justifyContent:'space-around', }}>
                      <button className="btn "  style={{backgroundColor:'green', color:'white'}}  onClick={() => window.open(`https://wa.me/${res.whatsapp_number}`, "_blank")}><FaWhatsapp className="p-0 m-0 mb-1 me-1" />
                    Book Appointment</button>
 

         <button className="btn "  style={{backgroundColor:'green', color:'white'}}  onClick={() => window.location.href = `tel:${res.whatsapp_number}`}><VscCallOutgoing />  </button>
                      </div>
                      </div>

                      

                     
                    </div>
                  </div>

                
                  {/* <div
                    style={{
                      height: "180px",
                      width: "100%",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",  
                      // border:"1px solid red"
                    }}
                  >
                    <img
                      src={res.subCategory_image}
                      alt={res.name}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                        // backgroundSize:'contin',
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      
                      }}
                    />
                  </div> 
                  <div
                    style={{
                      backgroundColor: "#faf5f5",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                      borderBottomRightRadius: "10px",
                      borderEndStartRadius: "10px",
                      padding: "10px",
                    }}
                  >
                    <h4>{res.name} </h4>
                    <hp>{res.address}</hp>
                    <p>{res.designation}</p>
                    <p>{res.city}</p>
                    <p>{res.whatsapp_number}</p>

                    <button className="btn "  style={{backgroundColor:'green', color:'white'}}  onClick={() => window.open(`https://wa.me/${res.whatsapp_number}`, "_blank")}><FaWhatsapp className="p-0 m-0 mb-1 me-2" />
                    Book Appointment</button>
 

         <button className="btn mx-3"  style={{backgroundColor:'green', color:'white'}}  onClick={() => window.location.href = `tel:${res.whatsapp_number}`}><VscCallOutgoing />  </button>

                  </div> */}
                </div>
              ))
            ) : (
              <div className="text-center mt-5">
                <h5>No data found</h5>
              </div>
            )}
          </div>
        </div>
      </section>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
          color="primary"
        />
      </div>

      <section className="m-0" style={{ marginTop: "auto" }}>
        <Footer />
      </section>
    </div>
  );
}
