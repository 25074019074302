import React, { useEffect, useState } from "react";
import { useToken } from "./store";

const VideoList = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { token, setToken } = useToken((state) => state);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`); // Ensure you have a valid token

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const response = await fetch(
          "https://newapi.jaishreeramhealthcareprivateltd.com/videos/list",
          requestOptions
        );
        const result = await response.json();

        console.log(result);

        if (result.status === "001") {
          setVideos(result?.videoPaths);
        } else {
          setError("Failed to fetch videos");
        }
      } catch (err) {
        setError("An error occurred while fetching videos");
        console.error("Error:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, []); // Empty dependency array to run the fetch only once when component mounts

  const handleVideoClick = (videoUrl) => {
    // Open video in a new tab and make it play there
    const videoWindow = window.open(videoUrl, "_blank");
    videoWindow.focus();
  };

  if (loading) {
    return <div>Loading videos...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container">
      <div className="row">
        {/* Loop through the videos array and display them in a 3-column layout */}
        {videos?.map((videoUrl, index) => (
          <div key={index} className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: "20px" }}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleVideoClick(videoUrl)}
            >
              {/* Display full video without autoplay on the current page */}
              <video width="100%" height="200px" controls>
                <source src={videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoList;
