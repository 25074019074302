import React from 'react'

const Terms = () => {
  return (
    <div>
        <div className='container mt-4'>
            <div className='row'>
                <div className='col-lg-12'>

                <div className="terms-container">
      <h1>Terms & Conditions for Jai Shri Ram Healthcare Pvt. Ltd.</h1>
      <p className="effective-date">Effective Date: 27-Nov-2024</p>
      <p>
        Welcome to Jai Shri Ram Healthcare Pvt. Ltd. (“Jai Shri Ram Healthcare”).
        These Terms & Conditions (“Terms”) govern your access to and use of our
        website and services. By using our platform, you agree to comply with
        and be bound by these Terms. Please read them carefully before accessing
        our services.
      </p>
      <hr />
      <section>
        <h2>1. Acceptance of Terms</h2>
        <p>
          By accessing or using Jai Shri Ram Healthcare, you agree to be legally
          bound by these Terms. If you do not agree to these Terms, please
          discontinue the use of our platform immediately.
        </p>
        <p>
          We reserve the right to update or modify these Terms at any time
          without prior notice. Your continued use of our services following any
          changes constitutes your acceptance of the updated Terms.
        </p>
      </section>
      <section>
        <h2>2. User Eligibility</h2>
        <p>By using Jai Shri Ram Healthcare, you represent that you:</p>
        <ul>
          <li>Are at least 18 years of age or have parental consent to use our services.</li>
          <li>Have the legal capacity to agree to these Terms and enter into a binding agreement.</li>
        </ul>
        <p>
          If you do not meet these eligibility requirements, you may not access
          or use our platform.
        </p>
      </section>
      <section>
        <h2>3. Account Registration</h2>
        <p>
          To access certain services, you may be required to create an account
          with accurate and complete information.
        </p>
        <ul>
          <li>You are responsible for maintaining the confidentiality of your login details.</li>
          <li>
            Notify us immediately at <a href="mailto:support@jaishreeramhealthcare.com">support@jaishreeramhealthcare.com</a> of
            any unauthorized use of your account.
          </li>
        </ul>
        <p>
          We reserve the right to suspend or terminate accounts suspected of unauthorized access or security breaches.
        </p>
      </section>
      {/* Add other sections here similarly */}
      <section>
        <h2>13. Contact Us</h2>
        <p>
          If you have any questions, concerns, or requests regarding these
          Terms & Conditions, you may contact us at:
        </p>
        <address>
          <strong>Jai Shri Ram Healthcare Pvt. Ltd.</strong> <br />
          <strong>Address:</strong> Coda Rasta, Jaipur, Rajasthan - 302001 <br />
          <strong>Phone:</strong> +91-9887251000 <br />
          <strong>Email:</strong>{" "}
          <a href="mailto:support@jaishreeramhealthcare.com">support@jaishreeramhealthcare.com</a>
        </address>
      </section>
    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Terms