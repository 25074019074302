import React from 'react';
import './Footer.css'; 
import jai from '../Compontes/Img/jai.png';


const Footer = () => {
  return (
    <footer className="footer-distributed ">
      <div className="footer-left">
        <img src={jai} style={{height:50,width:200,objectFit:"contain"}}/>
        

        

        <p className="footer-company-name mt-3" style={{color:"white"}}>JaishreeRam Healthcare</p>
        <div className='mt-3 text-white'>
        <p>
        <span>Address:
       </span>123 Health Street,Jaipur, Rajasthan, 302001,India</p>
      </div>
      </div>

      <div className="footer-center">
        <div>
          <p style={{fontWeight:600,fontSize:30}}>
          सबसे सस्ती फार्मेसी
         </p>
        </div>

        <div>
         
        </div>

        <div>
          <p className="mt-5 text-white"><a href="mailto:support@company.com" style={{color:"white"}}>contact@jaishriramhealthcare.com</a></p>
        </div>
      </div>

      <div className="footer-right">
        <p className="footer-company-about">
          <span>Download Our App</span>
         For a seamless healthcare experience, 
download our app today:
        </p>

        <div className="footer-icons">
        <p className="footer-company-name" style={{color:"white"}}>Contact :<span>+91 9653778555 , 9887251000</span></p>

        </div>
      </div>
    </footer>
  );
};

export default Footer;