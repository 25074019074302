import React, { useState, useEffect } from 'react';
import './Loginscrren.css';
import jai from '../Compontes/Img/jai.png';
import OtpInput from 'react-otp-input';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { BallTriangle } from 'react-loader-spinner';
import { useToken } from '../store';

const LoginOtp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { contact } = location.state || {};
  console.log(contact)
  const [otp, setOtp] = useState();
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(60);
  const [canResend, setCanResend] = useState(false);
  const {token, setToken} = useToken(state=>state)
  // const [phone, setPhone] = useState('');
const [showOtpInput, setShowOtpInput] = useState(false);
 
const phone = location.state?.phone;
  
  useEffect(() => {
    if (counter > 0) {
      const timer = setInterval(() => {
        setCounter(prevCounter => prevCounter - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [counter]);


  // const handlePhoneChange = (e) => setPhone(e.target.value);
  const handleOtpChange = (e) => setOtp(e.target.value);


const verifyotps = async ()=>{
  setLoading(true)
  try {
    const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");


const raw = JSON.stringify({
  "mobile": phone,
  "otp": otp
});

const requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: raw,
  redirect: "follow"
};

fetch("https://newapi.jaishreeramhealthcareprivateltd.com/users/verify-otp", requestOptions)
  .then((response) => response.json())
  .then((result) => {
    if (result.message) {
      toast.success(result.message, {
        autoClose: 3000,
      });

      // Save the token to localStorage
      localStorage.setItem("mobile",result.mobile);
      if (result.user_data === "Available") {
      setToken(result.token)
      navigate("/home");
    } else {
      // setToken(result.token)

      navigate("/signup");
    }
 
      setOtp("");
    } else {
      toast.error(result.message || "OTP verification failed", {
        autoClose: 3000,
      });
    }
  }).finally(()=>{
    setLoading(false)
  })
  } catch (error) {
    console.log(error)
  }
}

const logins = async()=>{
  setLoading(true)
  try {
    const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

const raw = JSON.stringify({
"mobile": phone
});

const requestOptions = {
method: "POST",
headers: myHeaders,
body: raw,
redirect: "follow"
};

fetch("https://newapi.jaishreeramhealthcareprivateltd.com/users/login", requestOptions)
.then((response) => response.json())
.then((result) => {
  if(result.success === true){

    localStorage.setItem("token", result.token) 
    // navigate("/home");
    window.location.href="/home"
  
    toast.success(result.message , {
      autoClose: 3000,
      
    });
  }else{
    toast.error(result.message || "OTP verification failed", {
      autoClose: 3000,
    });
  }
}).finally(()=>{
  setLoading(false)
})
  } catch (error) {
    console.log(error)
  }
}











 

  const initiatePhoneAuth = () => {
    window.OTPlessSignin.initiate({
      channel: "PHONE",
      phone,
      countryCode: "+91",
    });
    setShowOtpInput(true);
  };

  const verifyOtp = async () => {
    try {
      setLoading(true);

      // Call the OTP verification API
      const response = await window.OTPlessSignin.verify({
        channel: "PHONE",
        phone,
        otp,
        countryCode: "+91",
      });

      console.log("OTP Verification Response:", response);

      // Check response for success
      if (response && response.success) { 

        logins();
           
      } else {
        throw new Error("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error.message || error);
      alert(error.message || "OTP verification failed.");
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  useEffect(() => {
    const callback = (eventCallback) => {
      const { responseType, response } = eventCallback;

      const handlers = {
        ONETAP: () => {
          const { token } = response;
          console.log("One-tap sign-in successful:", { response, token });
        },
        OTP_AUTO_READ: () => {
          const { otp } = response;
          console.log("OTP auto-read:", otp);
        },
        FAILED: () => {
          console.error("Authentication failed:", response);
        },
        FALLBACK_TRIGGERED: () => {
          console.warn("Fallback triggered:", response);
        },
      };

      if (handlers[responseType]) {
        handlers[responseType]();
      } else {
        console.warn("Unhandled response type:", responseType);
      }
    };

    window.OTPlessSignin = new window.OTPless(callback);
  }, []);


  return (
    <div style={{ overflowX: "hidden" }}>
      <ToastContainer />
      <div className="login-container">
        <div className="login-left-pane">
          <div className="login-logo">
            <div className="login-logo1">
              <img src={jai} alt="jai" style={{ height: "100%", width: "100%", objectFit: "cover" }} />
            </div>
          </div>

          <h1 className='pt-3'>Welcome<br />Jai Shree Ram Healthcare<br />Pvt. Ltd.!</h1>
          <p className="login-description pt-3">Your one-stop solution for comprehensive healthcare services. We believe in providing prompt and personalized care to all our patients, ensuring that when you need us, we’re always there with the answers you seek.</p>
        </div>
        <div className="login-right-pane-centered">
          <h4 style={{ textAlign: "center", paddingRight: "120px" }}>Enter OTP</h4>
          <div className="otp-screen">
      <div className="otp-input-container">
        <OtpInput
          value={otp} // Use OTP state here
          onChange={setOtp} // Update OTP value on input change
          numInputs={6}
          renderSeparator={<span> </span>}
          renderInput={(props) => <input {...props} />}
          inputStyle={{
            width: 50,
            height: 40,
            margin: "10px",
            borderRadius: "10px",
            marginLeft: "0px",
            border: "2px solid #00A3FF",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
        />
      </div>


      <div  style={{display:'flex', justifyContent:'center'}}>
      <button className='btn my-3'  style={{backgroundColor: "#FF1493",width: "35%",padding: "10px",color: "white",fontSize: "17px",
          border: "none",borderRadius: "20px",alignSelf: "center",boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          display: "flex",alignItems: "center",justifyContent: "center",}}
          onClick={verifyOtp}  disabled={loading} // Disable button when loading
      >
        {loading ? (
          <BallTriangle
            height={50}
            width={50}
            radius={5}
            color="#4fa94d"
            ariaLabel="ball-triangle-loading"
            visible={true}
          />
        ) : (
          "Continue"
        )}
      </button>
      </div>
    </div>



          {/* <div>
          <input
            type="text"
            value={otp}
            onChange={handleOtpChange}
            placeholder="Enter OTP"
          />
          <button onClick={verifyOtp}>Verify OTP</button>
        </div> */}


          {counter > 0 ? (
            <p style={{ textAlign: "center", color: "red" }}>Resend OTP in {counter} seconds</p>
          ) : (
            <button
              className=""
              style={{
                background: "none",
                width: "35%",
                padding: "10px",
                fontSize: "17px",
                border: "none",
                borderRadius: "20px",
                color: "blue",
                textAlign: "center",
                alignSelf: "center"
              }}
              disabledd={!canResend}
            onClick={logins}
            >
              Resend
            </button>
          )}
        </div>
      </div>
    </div> 
  );
}

export default LoginOtp;

