import React, { useEffect, useState } from 'react';
import './Loginscrren.css';
import jai from '../Compontes/Img/jai.png';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { BallTriangle } from 'react-loader-spinner';

const LoginScreen = () => {
  const navigate = useNavigate();
  const [contact, setContact] = useState("");
  const [loading, setLoading] = useState(false);


 
  
  const logins = async()=>{
    setLoading(true)
    try {
      const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

const raw = JSON.stringify({
  "mobile": contact
});

const requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: raw,
  redirect: "follow"
};

fetch("https://newapi.jaishreeramhealthcareprivateltd.com/users/login", requestOptions)
  .then((response) => response.json())
  .then((result) => {
    if(result.success=== true){
      navigate("/LoginOtp",{ state: { contact:contact} })
      setContact("")
      toast.success(result.message, { autoClose: 3000 });
           
    }else {
      toast.error(result.message || "Error updating profile");
    }
  }).finally(()=>{
    setLoading(false)
  })
    } catch (error) {
      console.log(error)
    }
  }















  
const [phone, setPhone] = useState('');
const [otp, setOtp] = useState('');
const [showOtpInput, setShowOtpInput] = useState(false);

  const [error, setError] = useState("");

// const handlePhoneChange = (e) => setPhone(e.target.value);


const handlePhoneChange = (e) => {
  const value = e.target.value;

  // Allow only numeric input with max length of 10 and starting with 7, 8, or 9
  if (/^\d*$/.test(value) && value.length <= 10) {
    setPhone(value);

    // Clear error if length reaches 10
    if (value.length === 10) {
      setError("");
    }
  }
};

const handleBlur = () => {
  // Show error if length is less than 10
  if (phone.length > 0 && phone.length < 10) {
    setError("Phone number must be exactly 10 digits long.");
  }
};

const handleOtpChange = (e) => setOtp(e.target.value);

const initiatePhoneAuth = () => {
  window.OTPlessSignin.initiate({
    channel: 'PHONE',
    phone,
    countryCode: '+91',  
  });
  setShowOtpInput(true);
  navigate('/LoginOtp', { state: { phone } });
};

const verifyOtp = () => {
  window.OTPlessSignin.verify({
    channel: 'PHONE',
    phone,
    otp,
    countryCode: '+91',  
  });
};

const initiateOAuth = (provider) => {
  window.OTPlessSignin.initiate({
    channel: 'OAUTH',
    provider,
  });
};


useEffect(() => {
  const callback = (eventCallback) => {
    const { responseType, response } = eventCallback;

    const handlers = {
      ONETAP: () => {
        const { token } = response;
        console.log('One-tap sign-in successful:', { response, token }); 
      },
      OTP_AUTO_READ: () => {
        const { otp } = response;
        console.log('OTP auto-read:', otp); 
      },
      FAILED: () => {
        console.error('Authentication failed:', response); 
      },
      FALLBACK_TRIGGERED: () => {
        console.warn('Fallback triggered:', response); 
      },
    };

    if (handlers[responseType]) {
      handlers[responseType]();
    } else {
      console.warn('Unhandled response type:', responseType);
    }
  };

  
  window.OTPlessSignin = new window.OTPless(callback);
}, []);








 

  return (
    <div style={{ overflowX: "hidden" }}>
      <ToastContainer />
      <div className="login-container">
        <div className="login-left-pane">
          <div className="login-logo">
            <div className="login-logo1">
              <img src={jai} alt="jai" style={{ height: "100%", width: "100%", objectFit: "cover" }} />
            </div>
          </div>
          <h1 className="pt-3">Welcome<br />Jai Shree Ram Healthcare<br />Pvt. Ltd.!</h1>
          <p className="login-description pt-3">
            Your one-stop solution for comprehensive healthcare services. We believe in providing prompt and personalized care to all our patients, ensuring that when you need us, we’re always there with the answers you seek.
          </p>
        </div>
        <div className="login-right-pane">
          <div className="login-form-container">
            <h3 style={{ textAlign: "center", paddingTop: "100px", fontSize: "32px", color: "#FF1493" }}>Login</h3>
            <div style={{ paddingTop: "50px" }}>
              <h2>Enter your Number</h2>
              <div className="login-input-container">
              <div>
      <input
        type="text"
        placeholder="Enter Your Number"
        value={phone}
        onChange={handlePhoneChange}
        onBlur={handleBlur}
        className="login-input"
        maxLength={10} // Ensures no more than 10 characters can be typed
        inputMode="numeric" // Helps mobile devices display numeric keyboards
      />
      {error && <p style={{ color: "red", fontSize: "12px" }}>{error}</p>}
    </div>
              </div>
              <div style={{ padding: "19px", paddingTop: "10px", alignContent: "center", justifyContent: "center", display: "flex" }}>
                
      <button
        style={{
          backgroundColor: phone.length === 10 ? "#FF1493" : "#ccc",
          width: "100%",
          padding: "10px",
          color: "white",
          fontSize: "17px",
          border: "none",
          borderRadius: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: phone.length === 10 ? "pointer" : "not-allowed",
        }}
        onClick={initiatePhoneAuth}
        disabled={phone.length !== 10} // Disable button if length is not 10
      >
        {loading ? (
          <BallTriangle
            height={50}
            width={50}
            radius={5}
            color="#4fa94d"
            ariaLabel="ball-triangle-loading"
            visible={true}
          />
        ) : (
          "Continue"
        )}
      </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginScreen;





































// import React, { useState, useEffect } from 'react';
// import messaging from './Firebase'; // Import Firebase config
// import { getToken, onMessage } from 'firebase/messaging';
// import { useNavigate } from 'react-router-dom';
// import { toast, ToastContainer } from 'react-toastify';
// import { BallTriangle } from 'react-loader-spinner'; 

// const LoginScreen = () => {
//   const navigate = useNavigate();
//   const [contact, setContact] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [fcmToken, setFcmToken] = useState('');

//   // Request permission for notifications and get FCM Token
//   useEffect(() => {
//     const requestPermissionAndGetToken = async () => {
//       try {
//         const permission = await Notification.requestPermission();
//         if (permission === 'granted') {
//           const token = await getToken(messaging, {
//             vapidKey: 'YOUR_PUBLIC_VAPID_KEY', // Obtain from Firebase console
//           });
//           setFcmToken(token);
//           console.log('FCM Token:', token);
//           // You can send this token to your backend server
//         } else {
//           console.warn('Notification permission not granted.');
//         }
//       } catch (error) {
//         console.error('Error fetching FCM token:', error);
//       }
//     };

//     requestPermissionAndGetToken();

//     // Listen for messages while the app is in the foreground
//     onMessage(messaging, (payload) => {
//       console.log('Message received: ', payload);
//       toast.info(payload.notification.body);
//     });
//   }, []);

//   const logins = async () => {
//     setLoading(true);
//     try {
//       const response = await fetch(
//         'https://newapi.jaishreeramhealthcareprivateltd.com/users/login',
//         {
//           method: 'POST',
//           headers: { 'Content-Type': 'application/json' },
//           body: JSON.stringify({ mobile: contact, fcmToken }),
//         }
//       );
//       const result = await response.json();
//       if (result.success) {
//         toast.success(result.message, { autoClose: 3000 });
//         navigate('/LoginOtp', { state: { contact } });
//         setContact('');
//       } else {
//         toast.error(result.message || 'Login failed');
//       }
//     } catch (error) {
//       console.error('Error during login:', error);
//       toast.error('An error occurred. Please try again.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div style={{ overflowX: 'hidden' }}>
//       <ToastContainer />
//       <div className="login-container">
//         <div className="login-left-pane">
//           <h1>Welcome to Jai Shree Ram Healthcare Pvt. Ltd.</h1>
//           <p>Your trusted healthcare partner, always here for you.</p>
//         </div>
//         <div className="login-right-pane">
//           <div className="login-form-container">
//             <h3>Login</h3>
//             <input
//               type="number"
//               placeholder="Enter Your Mobile Number"
//               value={contact}
//               onChange={(e) => setContact(e.target.value)}
//               className="login-input"
//               maxLength={10}
//             />
//             <button onClick={logins} disabled={loading} className="login-button">
//               {loading ? (
//                 <BallTriangle height={30} width={30} color="#fff" />
//               ) : (
//                 'Continue'
//               )}
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default LoginScreen;
