import React from 'react';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <div style={styles.container}>
      <div style={styles.scrollContainer}>
        <div className='mt-5' style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center' }}>
          <button onClick={() => navigate(-1)} style={styles.backButton}>
            
          </button>
          <h1 style={styles.heading}>Privacy Policy</h1>
        </div>

        <p style={styles.text}>
          At Jai Shri Ram Healthcare Pvt. Ltd. ("Jai Shri Ram Healthcare," "we," "our," or "us"), your privacy is of utmost importance to us. This Privacy Policy outlines how we collect, use, and protect your personal information when you visit our website or use our mobile application ("App").
        </p>

        <p style={styles.text}>
          By using Jai Shri Ram Healthcare services, you agree to the collection and use of information as outlined in this policy. If you do not agree with the terms of this policy, please discontinue the use of our services.
        </p>

        <h2 style={styles.sectionTitle}>1. Information We Collect</h2>
        <p style={styles.text}>
          We collect personal and non-personal information to provide you with an efficient, safe, and customized experience. The types of information we collect include:
        </p>

        <h3 style={styles.subSectionTitle}>Personal Information:</h3>
        <p style={styles.text}>
          This may include your name, contact number, email address, date of birth, address, medical history, payment information, and any other information required for the services you use.
        </p>

        <h3 style={styles.subSectionTitle}>Usage Data:</h3>
        <p style={styles.text}>
          We automatically collect information about your interaction with our website and app, such as your IP address, browser type, mobile device type, operating system, and the pages or features of our platform you access.
        </p>

        <h3 style={styles.subSectionTitle}>Cookies and Tracking Technologies:</h3>
        <p style={styles.text}>
          We use cookies and similar tracking technologies to enhance your experience on Jai Shri Ram Healthcare. Cookies help us track user activity and remember your preferences.
        </p>

        <h2 style={styles.sectionTitle}>2. How We Use Your Information</h2>
        <p style={styles.text}>
          We use the information we collect for the following purposes:
        </p>
        <ul>
          <li style={styles.text}>To Provide Services: Your information is used to provide health consultations, book lab tests, and deliver services like online medicine orders and e-commerce features.</li>
          <li style={styles.text}>To Personalize Your Experience: We tailor our services and content based on the information you provide and your usage patterns.</li>
          <li style={styles.text}>To Improve Our Services: Usage data helps us enhance the functionality, performance, and quality of our platform.</li>
          <li style={styles.text}>To Communicate with You: Notifications, updates, promotional materials, and other communications related to your account and our services.</li>
          <li style={styles.text}>For Legal Purposes: We may use your information to comply with legal obligations or protect the rights, safety, and property of Jai Shri Ram Healthcare, its users, and the public.</li>
        </ul>

        <h2 style={styles.sectionTitle}>3. Data Sharing and Disclosure</h2>
        <p style={styles.text}>
          We do not sell or rent your personal information to third parties. However, we may share your data under the following circumstances:
        </p>
        <ul>
          <li style={styles.text}>With Service Providers: We may share your information with trusted third-party service providers, such as payment processors, cloud hosting services, and customer support.</li>
          <li style={styles.text}>For Legal Compliance: Your personal information may be disclosed to comply with applicable laws, regulations, or legal processes.</li>
          <li style={styles.text}>With Your Consent: We may share your information with third parties when you explicitly provide your consent.</li>
        </ul>

        <h2 style={styles.sectionTitle}>4. Data Retention</h2>
        <p style={styles.text}>
          We will retain your personal information for as long as necessary to provide the services you request, comply with legal obligations, resolve disputes, and enforce our agreements. Once your information is no longer required, we will securely delete or anonymize it.
        </p>

        <h2 style={styles.sectionTitle}>5. Cookies Policy</h2>
        <p style={styles.text}>
          Jai Shri Ram Healthcare uses cookies to collect information and enhance your user experience. Cookies are small data files stored on your device.
        </p>

        <h3 style={styles.subSectionTitle}>Types of Cookies Used:</h3>
        <ul>
          <li style={styles.text}>Session Cookies: Temporary cookies that expire once you close your browser, used to manage session data during your visit.</li>
          <li style={styles.text}>Persistent Cookies: Cookies that remain on your device for a set period, helping us remember your preferences and usage patterns.</li>
        </ul>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    backgroundColor: '#fff',
  },
  scrollContainer: {
    maxWidth: '800px',
    margin: '0 auto',
  },
  backButton: {
    border: 'none',
    background: 'none',
    padding: 0,
    cursor: 'pointer',
  },
  backIcon: {
    height: '30px',
    width: '30px',
  },
  heading: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  sectionTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginTop: '20px',
    marginBottom: '10px',
  },
  subSectionTitle: {
    fontSize: '16px',
    fontWeight: '600',
    marginTop: '10px',
    marginBottom: '5px',
  },
  text: {
    fontSize: '14px',
    lineHeight: '1.5',
    color: '#333',
    marginBottom: '10px',
  },
};

export default PrivacyPolicy;
