import React, { useEffect } from "react";
import fedex from "../Compontes/Img/fedex.png";
import arrow from "../Compontes/Img/arrow.png";
import { FaWhatsapp } from 'react-icons/fa';
import lic from "../Compontes/Img/lic.png";

import teamleader from "../Compontes/Img/AMITPIC.png";
import teamleader01 from "../Compontes/Img/teamleader01.png";
import swati from "../Compontes/Img/swati.jpeg";
import rajni from "../Compontes/Img/rajni.jpg";
import ICIC from "../Compontes/Img/icic.png";
import digit from "../Compontes/Img/digit.png";
import birla from "../Compontes/Img/birla.png";
import hdfc from "../Compontes/Img/hdfc.png";
import sbi from "../Compontes/Img/sbi.png";
import niva from "../Compontes/Img/niva.png";
import background1 from "../Compontes/Img/why.png";
import auth from "../Compontes/Img/auth.png";
import phone from "../Compontes/Img/phone.png";
import tokia from "../Compontes/Img/tokia.png";
import lipboard from "../Compontes/Img/lipboard.png";
import future from "../Compontes/Img/future.png";
import chala from "../Compontes/Img/chalo.png";
import cl from "../Compontes/Img/cl.png";
import group from "../Compontes/Img/Group.png";
import jai from '../Compontes/Img/jai.png';
import tata from "../Compontes/Img/tata.png";
import Footer from "./Footer";
import ResponsiveSlider from "./ResponsiveSlider";
import { NavLink, useNavigate } from "react-router-dom";

function Landing() {


  const navigation = useNavigate()
  const data1 = [
    {
      id: 1,
      img: fedex,
    },
    {
      id: 2,
      img: tokia,
    },
    {
      id: 3,
      img: lipboard,
    },
    {
      id: 4,
      img: digit,
    },
    {
      id: 5,
      img: birla,
    },
    {
      id: 6,
      img: future,
    },
    {
      id: 7,
      img: chala,
    },
    {
      id: 8,
      img: cl,
    },
    {
      id: 9,
      img: group,
    },
    {
      id: 10,
      img: cl,
    },
    {
      id: 11,
      img: chala,
    },
    {
      id: 12,
      img: digit,
    },
    {
      id: 13,
      img: lipboard,
    },
    {
      id: 14,
      img: birla,
    },
    {
      id: 15,
      img: digit,
    },
    {
      id: 16,
      img: chala,
    },
    {
      id: 17,
      img: birla,
    },
    {
      id: 18,
      img: chala,
    },
  ];

  const team = [
    {
      id: 1,
      Name: "GS Malthora",
      des: "Tech Partner",
      img: teamleader01,
    },
    {
      id: 2,
      Name: "Rajni Arora",
      des: "cylcogist",
      img: swati,
    },
    {
      id: 3,
      Name: " Savitha Prabhumurthy",
      des: "Bangalore Partner",
      img: rajni,
    },
  ];
  const Dealers = [
    {
      id: 1,
      name: "LIC",
      img: lic,
    },
    {
      id: 2,
      name: "ICICI",
      img: ICIC,
    },
    {
      id: 3,
      name: "Digit",
      img: digit,
    },
    {
      id: 4,
      name: "Aditya Birla",
      img: birla,
    },
    {
      id: 5,
      name: "HDFC ERGO",
      img: hdfc,
    },
    {
      id: 6,
      name: "SBI",
      img: sbi,
    },
    {
      id: 7,
      name: "NIVA",
      img: niva,
    },
    {
      id: 8,
      name: "TATA",
      img: tata,
    },
  ];
  return (
    <div style={{ overflowX: "hidden" }}>
      <div>
        <nav className="navbar navbar-expand-lg fixed-top" style={{ backgroundColor: "#FF69B4" }}>
          <div className="container-fluid">
            <a className="navbar-brand" href="#">
              <img src={jai} alt="Logo" className="navbar-logo" />
            </a>
            <button className="navbar-toggler" style={{ backgroundColor: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <div style={{ display: 'flex', gap: '20px' }}>
                  <button onClick={() => navigation("/loginNumber")} className="login">Login Now</button>
                  <button type="button" onClick={() => navigation("/SignUp")} className="login">Sign Up</button>
                </div>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <section>
        <div
          className="container-fluid"
          style={{
            backgroundColor: "#FF69B4",
          }}
        >
          <div className="row pt-5">
            <div className="col-lg-6 col-sm-12 col-md-12 mx-auto justify-content-center align-items-center d-grid text-white p-5 g-0 mt-4">
              <p
                className=" fw-bolder text-center"
                style={{ fontFamily: "Inter", fontSize: "49px" }}
              >
                Welcome
              </p>
              <p
                className="fw-bolder text-center"
                style={{
                  marginTop: -15,
                  fontFamily: "Inter",
                  fontSize: "26px",
                }}
              >
                Jai Shree Ram Healthcare Pvt. Ltd.!
              </p>
              <p className="text-center fs-6">
                Your one-stop solution for comprehensive healthcare services. We
                believe in providing prompt and personalized care to all our
                patients, ensuring that when you need us, we're always there
                with the answers you seek.
              </p>
              <div className="justify-content-center align-content-center d-flex">
                <NavLink to={"/loginNumber"}>
                  <button className="login p-2 m-2">Login Now</button>

                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <ResponsiveSlider />
      </section>

      <section>
        <div className="container-fluid py-5" style={{
          backgroundColor: "#FF69B4",
        }}>
          <div className="row">
            <div className="col-12 text-center mb-4">
              <h3 className="fs-1" style={{ color: "#C71585" }}>About</h3>
            </div>
            <div className="col-lg-1 col-md-0"></div>
            <div className="col-lg-6  col-12">
              <div className=" p-3 border-0 bg-transparent" style={{ fontFamily: "Inter" }}>
                <p style={{ color: "white" }}>Amit Mittal is a distinguished professional in the healthcare and pharmaceutical industry, associated with multiple prestigious organizations:</p>
                {[
                  "Media Press Reporter: DHAKAD NEWS",
                  "Forti: Medical & Healthcare Committee Member",
                  "Vice President: Jaipur Chemist Association",
                  "Civil Lines Secretary: Agra Yuva Shakti Medical Division",
                  "Pradesh Swasthya Salahkar Mantri: Akhil Bhartiya Vaishya Maha Sammelan, Rajasthan",
                  "Member: Bharat Vikas Parishad Manasarovar",
                  "Medical Wing Member: Pradesh Agarwal Maha Sabha Sammelan",
                  "Active Participant: Agarwal Dawa Vyavsay Sangathan (ADVS)",
                ].map((text, index) => (
                  <div key={index} className="d-flex align-items-center gap-2 mb-2">
                    <img src={arrow} alt="arrow" style={{ height: 20, width: 20 }} />
                    <p className="m-0 text-center">{text}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 d-flex justify-content-center pt-4">
              <div className=" border-0 bg-transparent">
                <div style={{
                  height: 280, width: "300px", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundColor: "white", marginBottom: "200px",
                  boxshadow: ' rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', borderTopLeftRadius: "5px", borderTopRightRadius: "5px", borderWidth: "1px solid red"

                }}>
                  <img
                    src={teamleader}
                    alt="team leader"
                    style={{ height: "100%", width: "100%", objectFit: "cover", }}
                  />
                  <h3 className="text-center pt-1 text-white p-3" style={{ backgroundColor: "#C71585" }}>Founder & CEO</h3>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section>
        <div
          className="container-fluid"
          style={{
            backgroundImage: `url(${background1})`,
            width: "100%",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            height: "100%",
          }}
        >
          <div className="row pt-5 pb-5">
            <div className="justify-content-center align-items-center">
              <h3
                className="text-center pt-5 fs-1"
                style={{
                  color: "#FF1493",
                }}
              >
                Why Choose Us
              </h3>
            </div>
            <div className="col-lg-6 col-sm-12 col-md-12 mx-auto">
              <p className="text-center fs-4 pt-2" style={{ color: "#FF1493" }}>
                At Jai Shree Ram Healthcare Pvt. Ltd., we are committed to
                offering the highest quality of care with a personal touch. Our
                wide range of services, expert professionals, and advanced
                technologies ensure that you receive the best healthcare
                solutions efficiently and effectively. Join us and experience
                healthcare like never before.
              </p>
            </div>
          </div>
        </div>
      </section>

     {/* <section>
        <div className="container-fluid" style={{ backgroundColor: "#FF69B4" }}>
          <div className="row pt-5 pb-5">
            <div className="justify-content-center align-items-center d-grid">
              <p
                className="text-white fw-medium text-center"
                style={{ fontSize: "32px", fontFamily: "Inter" }}
              >
                Our Dealers
              </p>
              <p className="fs-4 text-white text-center">
                Insurance Leader For all Companies. All Kind of
                <br />
                Insurance Service Available
              </p>
            </div>
            <div className="d-flex flex-wrap justify-content-center">
              {Dealers.map((res, index) => {
                return (
                  <div
                    key={index}
                    className="col-lg-3 col-md-4 col-sm-6 col-xs-12 p-2"
                  >
                    <div
                      className="card justify-content-center align-items-center"
                      style={{
                        backgroundColor: "white",
                        border: "none",
                        borderRadius: 0,
                      }}
                    >
                      <div
                        className="mt-2"
                        style={{
                          height: 50,
                          width: 100,
                          backgroundSize: "content",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <img
                          src={res.img}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                      <p
                        className="fs-4 mt-2 fw-semibold"
                        style={{ color: "#093D65" }}
                      >
                        {res.name}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      <section>
        <div
          className="container-fluid"
          style={{
            backgroundImage: `url(${auth})`,
            width: "100%",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            height: "100%",
          }}
        >
          <div className="row pb-5 pt-5">
            <div className="d-flex justify-content-center align-items-center pt-5">
              <div className="justify-content-center align-items-center d-grid">
                <h3
                  className="text-center"
                  style={{ color: "#FF1493", fontSize: "32px" }}
                >
                  Authorised Stockist
                </h3>
                <p className="text-center fs-5">
                  As an authorised stockist, we are proud to offer a wide range
                  of genuine and <br />high-quality medical and healthcare products to
                  meet all your needs.<br /> Our commitment to  excellence ensures that
                  you receive only the <br /> best products available in the market.
                </p>
              </div>
            </div>
            <div className="col-lg-8 col-sm-12 col-md-12 mx-auto " >
              <div className="row">
                {data1.map((res, index) => {
                  return (
                    <div
                      key={index}
                      className=" col-lg-2 col-md-4 col-sm-12 col-6 p-2 "

                    >
                      <div
                        className="card"
                        style={{
                          border: "none",
                          background: "none",
                        }}
                      >
                        <div
                          style={{
                            height: 100,
                            width: 120,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
                            backgroundColor: "white",
                            padding: 10,
                          }}
                        >
                          <img
                            src={res.img}
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
*/}
      {/*  <section>
        <div className="container-fluid" style={{ backgroundColor: "#FF69B4" }}>
          <div className="row pt-5">
            <div className="d-flex justify-content-center align-items-center mt-5">
              <div>
                <h3
                  className="text-center"
                  style={{ color: "#C71585", fontSize: "32px" }}
                >
                  Our Partner
                </h3>
                {/*<h3
                    className="text-center"
                    style={{ color: "#0A2E72", fontSize: "32px" }}
                  >
                    Meet Our Expert Team 
                    Members
                  </h3>
              </div>
            </div>
            <div
              className="col-lg-3 col-sm-6 col-md-4 mt-4"
            ></div>
            {team.map((member) => (
              <div
                key={member.id}
                className="col-lg-2 col-sm-6 col-md-4 mt-4 "
              >
                <div
                  className="card"
                  style={{
                    border: "none",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    padding: 10,
                    paddingTop: 20,
                    width: "100%",
                    color: "white",
                    fontSize: 19,
                    background: "none",
                  }}
                >
                  <div
                    style={{
                      height: 150,
                      width: 150,
                      borderRadius: 100,
                      backgroundSize: "cover",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={member.img}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <p
                    className="text-center pt-3"
                    style={{
                      color: member.textColor,
                      fontFamily: "Ubuntu",
                      fontSize: "17px",
                      fontWeight: "700",
                    }}
                  >
                    {member.Name}
                  </p>
                  <p
                    className="text-center"
                    style={{
                      color: member.textColor,
                      fontFamily: "Ubuntu",
                      fontSize: "17px",
                      fontWeight: "700",
                      marginTop: "-20px",
                    }}
                  >
                    {member.des}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
          
      */}
      <section>
        <div
          className="container-fluid"
          style={{
            backgroundImage: `url(${phone})`,
            width: "100%",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            height: "100%",
          }}
        >
          <div className="row p-5">
            <div className="col-lg-8 col-sm-12 col-md-12 mx-auto">
              <h3
                className="text-center"
                style={{ color: "#C71585", fontSize: "32px" }}
              >
                Get in Touch
              </h3>
              <p className="text-center" style={{ color: "#C71585" }}>
                For any inquiries or to book our services, please contact Amit
                Mittal at 9653778555. We're here to help you with all your
                healthcare needs.
              </p>

              <div class="accordion" id="accordionExample">
                <div class="accordion-item  mb-2">
                  <h2 class="accordion-header" >
                    <button class="accordion-button" style={{ backgroundColor: "#FF69B4" }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      Q: How can I book a service?
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      You can easily book any of our services through our userfriendly online platform or by calling us at <strong> 9653778555</strong>  <code></code>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-2">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" style={{ backgroundColor: "#FF69B4" }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Q: Do you offer next day service?
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      We provide a next-day service booking system to promptly ensure you get the care you need.

                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-2">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" style={{ backgroundColor: "#FF69B4" }} type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Q: What kind of insurance policies do you offer?
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      We offer a range of comprehensive insurance plans tailored to meet your <strong>specific healthcare needs.</strong>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <a
        href="https://wa.me/9653778555"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          backgroundColor: '#25D366',
          color: 'white',
          borderRadius: '50%',
          width: '60px',
          height: '60px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
        }}
      >
        <FaWhatsapp size={30} />
      </a>
      <Footer />




    </div>
  );
}

export default Landing;
