

import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "../Footer";
import { BallTriangle } from "react-loader-spinner";
import { useToken } from "../../store";

function Plans() {
  const [loading, setLoading] = useState(false);
  const [plansData, setPlansData] = useState([]);
  const { token } = useToken(state => state);
  // console.log(plansData, "Plans Data");

  useEffect(() => {
    plansdatas();
  }, []);

  async function plansdatas() {
    setLoading(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      const response = await fetch("https://newapi.jaishreeramhealthcareprivateltd.com/plans/user/allPlans", requestOptions);
      const result = await response.json();
      // console.log(result);
      
      if (result.success === true) {
        setPlansData(result.plans);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <section>
        <Navbar />
      </section>
      <section>
        <div className="container pt-2" style={{ marginTop: "150px" }}>
          <h3 className="text-center text-black">Choose your Monthly Plan</h3>
          <div className="row justify-content-center align-items-center">
            {loading ? (
              <BallTriangle
                height={50}
                width={50}
                radius={5}
                color="#4fa94d"
                ariaLabel="ball-triangle-loading"
                visible={true}
              />
            ) : (
              plansData.map((res) => (
                <div key={res.id} className="col-lg-4 col-sm-12 col-md-4 mt-5">
                  <div
                    className="p-2 pt-3 pb-3"
                    style={{
                      border: "1px solid #FF0596",
                      borderRadius: 12,
                      boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                    }}
                  >
                    <h5 style={{ fontSize: "30px", color: "black", fontWeight: "700" }}>Free</h5>
                    <p style={{ fontSize: "12px", color: "black", fontWeight: "400" }}>
                      Take it for a test drive. Use it as long as you want.
                    </p>
                    <h5 style={{ fontSize: "30px", color: "black", fontWeight: "700", marginTop: "-10px" }}>
                      ₹ :{res.free.monthly}
                    </h5>
                    <h6 style={{ color: "black", fontWeight: "700", marginTop: "-10px" }}>Free forever</h6>
                    <h6 style={{ color: "black", fontWeight: "700", marginTop: "20px" }}>Banner Ads:</h6>
                    <p style={{ fontSize: "14px", color: "black", fontWeight: "400", marginTop: "-10px" }}>
                      Small Size banner ad (limited visibility)
                    </p>
                    <p style={{ fontSize: "12px", color: "black", fontWeight: "400", marginTop: "-10px" }}>
                      Video Uploads:
                    </p>
                    <h6 style={{ color: "black", fontWeight: "700", marginTop: "-15px" }}>No video uploads</h6>
                    <button
                      style={{
                        border: "1px solid #FF0596",
                        borderRadius: "12px",
                        fontSize: "30px",
                        fontWeight: "700",
                        fontFamily: "Lato",
                        width: "100%",
                        background: res.button === "Subscribe"
                          ? "linear-gradient(90deg, #C60000 0%, #FF0596 100%)"
                          : "none",
                        color: res.button === "Subscribe" ? "#FFFFFF" : "#000000"
                      }}
                    >
                      Get Started
                    </button>
                  </div>
                </div>
              ))
            )}
            
            {/* Lite Plan */}
            {plansData.map((res) => (
              <div key={res.id} className="col-lg-4 col-sm-12 col-md-4 mt-5">
                <div
                  className="p-2 pt-3 pb-3"
                  style={{
                    border: "1px solid #FF0596",
                    borderRadius: 12,
                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                  }}
                >
                  <h5 style={{ fontSize: "30px", color: "black", fontWeight: "700" }}>Lite</h5>
                  <p style={{ fontSize: "12px", color: "black", fontWeight: "400" }}>
                    Take it for a test drive. Use it as long as you want.
                  </p>
                  <h5 style={{ fontSize: "30px", color: "black", fontWeight: "700", marginTop: "-10px" }}>
                    ₹ :{res.lite.monthly}
                  </h5>
                  <h6 style={{ color: "black", fontWeight: "700", marginTop: "-10px" }}>Free forever</h6>
                  <h6 style={{ color: "black", fontWeight: "700", marginTop: "20px" }}>Banner Ads:</h6>
                  <p style={{ fontSize: "14px", color: "black", fontWeight: "400", marginTop: "-10px" }}>
                    Small Size banner ad (limited visibility)
                  </p>
                  <p style={{ fontSize: "12px", color: "black", fontWeight: "400", marginTop: "-10px" }}>
                    Video Uploads:
                  </p>
                  <h6 style={{ color: "black", fontWeight: "700", marginTop: "-15px" }}>No video uploads</h6>
                  <button
                    style={{
                      border: "1px solid #FF0596",
                      borderRadius: "12px",
                      fontSize: "30px",
                      fontWeight: "700",
                      fontFamily: "Lato",
                      width: "100%",
                      background: "linear-gradient(90deg, #C60000 0%, #FF0596 100%)"
                    }}
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            ))}
            
            {/* Pro Plan */}
            {plansData.map((res) => (
              <div key={res.id} className="col-lg-4 col-sm-12 col-md-4 mt-5">
                <div
                  className="p-2 pt-3 pb-3"
                  style={{
                    border: "1px solid #FF0596",
                    borderRadius: 12,
                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                  }}
                >
                  <h5 style={{ fontSize: "30px", color: "black", fontWeight: "700" }}>Pro</h5>
                  <p style={{ fontSize: "12px", color: "black", fontWeight: "400" }}>
                    Unlock all features and enjoy premium services.
                  </p>
                  <h5 style={{ fontSize: "30px", color: "black", fontWeight: "700", marginTop: "-10px" }}>
                    ₹ :{res.pro.monthly}
                  </h5>
                  <h6 style={{ color: "black", fontWeight: "700", marginTop: "-10px" }}>Best for business</h6>
                  <h6 style={{ color: "black", fontWeight: "700", marginTop: "20px" }}>Banner Ads:</h6>
                  <p style={{ fontSize: "14px", color: "black", fontWeight: "400", marginTop: "-10px" }}>
                    Large Size banner ad (maximum visibility)
                  </p>
                  <p style={{ fontSize: "12px", color: "black", fontWeight: "400", marginTop: "-10px" }}>
                    Video Uploads:
                  </p>
                  <h6 style={{ color: "black", fontWeight: "700", marginTop: "-15px" }}>Unlimited uploads</h6>
                  <button
                    style={{
                      border: "1px solid #FF0596",
                      borderRadius: "12px",
                      fontSize: "30px",
                      fontWeight: "700",
                      fontFamily: "Lato",
                      width: "100%",
                      background: "linear-gradient(90deg, #C60000 0%, #FF0596 100%)"
                    }}
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="mb-5">
      <div className="container pt-2" style={{ marginTop: "50px" }}>
        <h3 className="text-center text-black">Choose your Yearly Plan</h3>
        <div className="row justify-content-center align-items-center">
          {loading ? (
            <BallTriangle
              height={50}
              width={50}
              radius={5}
              color="#4fa94d"
              ariaLabel="ball-triangle-loading"
              visible={true}
            />
          ) : (
            plansData.map((res) => (
              <div key={res.id} className="col-lg-4 col-sm-12 col-md-4 mt-5">
                <div
                  className="p-2 pt-3 pb-3"
                  style={{
                    border: "1px solid #FF0596",
                    borderRadius: 12,
                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                  }}
                >
                  <h5 style={{ fontSize: "30px", color: "black", fontWeight: "700" }}>Free</h5>
                  <p style={{ fontSize: "12px", color: "black", fontWeight: "400" }}>
                    Take it for a test drive. Use it as long as you want.
                  </p>
                  <h5 style={{ fontSize: "30px", color: "black", fontWeight: "700", marginTop: "-10px" }}>
                    ₹ :{res.free.yearly}
                  </h5>
                  <h6 style={{ color: "black", fontWeight: "700", marginTop: "-10px" }}>Free forever</h6>
                  <h6 style={{ color: "black", fontWeight: "700", marginTop: "20px" }}>Banner Ads:</h6>
                  <p style={{ fontSize: "14px", color: "black", fontWeight: "400", marginTop: "-10px" }}>
                    Small Size banner ad (limited visibility)
                  </p>
                  <p style={{ fontSize: "12px", color: "black", fontWeight: "400", marginTop: "-10px" }}>
                    Video Uploads:
                  </p>
                  <h6 style={{ color: "black", fontWeight: "700", marginTop: "-15px" }}>No video uploads</h6>
                  <button
                    style={{
                      border: "1px solid #FF0596",
                      borderRadius: "12px",
                      fontSize: "30px",
                      fontWeight: "700",
                      fontFamily: "Lato",
                      width: "100%",
                      background: res.button === "Subscribe"
                        ? "linear-gradient(90deg, #C60000 0%, #FF0596 100%)"
                        : "none",
                      color: res.button === "Subscribe" ? "#FFFFFF" : "#000000"
                    }}
                  >
                    Get Started
                  </button>
                </div>
              </div>
            ))
          )}
          
          {/* Lite Plan */}
          {plansData.map((res) => (
            <div key={res.id} className="col-lg-4 col-sm-12 col-md-4 mt-5">
              <div
                className="p-2 pt-3 pb-3"
                style={{
                  border: "1px solid #FF0596",
                  borderRadius: 12,
                  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                }}
              >
                <h5 style={{ fontSize: "30px", color: "black", fontWeight: "700" }}>Lite</h5>
                <p style={{ fontSize: "12px", color: "black", fontWeight: "400" }}>
                  Take it for a test drive. Use it as long as you want.
                </p>
                <h5 style={{ fontSize: "30px", color: "black", fontWeight: "700", marginTop: "-10px" }}>
                  ₹ :{res.lite.yearly}
                </h5>
                <h6 style={{ color: "black", fontWeight: "700", marginTop: "-10px" }}>Free forever</h6>
                <h6 style={{ color: "black", fontWeight: "700", marginTop: "20px" }}>Banner Ads:</h6>
                <p style={{ fontSize: "14px", color: "black", fontWeight: "400", marginTop: "-10px" }}>
                  Small Size banner ad (limited visibility)
                </p>
                <p style={{ fontSize: "12px", color: "black", fontWeight: "400", marginTop: "-10px" }}>
                  Video Uploads:
                </p>
                <h6 style={{ color: "black", fontWeight: "700", marginTop: "-15px" }}>No video uploads</h6>
                <button
                  style={{
                    border: "1px solid #FF0596",
                    borderRadius: "12px",
                    fontSize: "30px",
                    fontWeight: "700",
                    fontFamily: "Lato",
                    width: "100%",
                    background: "linear-gradient(90deg, #C60000 0%, #FF0596 100%)"
                  }}
                >
                  Subscribe
                </button>
              </div>
            </div>
          ))}
          
          {/* Pro Plan */}
          {plansData.map((res) => (
            <div key={res.id} className="col-lg-4 col-sm-12 col-md-4 mt-5">
              <div
                className="p-2 pt-3 pb-3"
                style={{
                  border: "1px solid #FF0596",
                  borderRadius: 12,
                  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                }}
              >
                <h5 style={{ fontSize: "30px", color: "black", fontWeight: "700" }}>Pro</h5>
                <p style={{ fontSize: "12px", color: "black", fontWeight: "400" }}>
                  Unlock all features and enjoy premium services.
                </p>
                <h5 style={{ fontSize: "30px", color: "black", fontWeight: "700", marginTop: "-10px" }}>
                  ₹ :{res.pro.yearly}
                </h5>
                <h6 style={{ color: "black", fontWeight: "700", marginTop: "-10px" }}>Best for business</h6>
                <h6 style={{ color: "black", fontWeight: "700", marginTop: "20px" }}>Banner Ads:</h6>
                <p style={{ fontSize: "14px", color: "black", fontWeight: "400", marginTop: "-10px" }}>
                  Large Size banner ad (maximum visibility)
                </p>
                <p style={{ fontSize: "12px", color: "black", fontWeight: "400", marginTop: "-10px" }}>
                  Video Uploads:
                </p>
                <h6 style={{ color: "black", fontWeight: "700", marginTop: "-15px" }}>Unlimited uploads</h6>
                <button
                  style={{
                    border: "1px solid #FF0596",
                    borderRadius: "12px",
                    fontSize: "30px",
                    fontWeight: "700",
                    fontFamily: "Lato",
                    width: "100%",
                    background: "linear-gradient(90deg, #C60000 0%, #FF0596 100%)"
                  }}
                >
                  Subscribe
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
      <section>
        <Footer />
      </section>
    </div>
  );
}

export default Plans;
